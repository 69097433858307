import { MarkerClusterer } from "MarkerClusterer";
bcl.c.distributor = {
  props: {
    className: "selectorSearch",
    classNameHidden: "hidden",
    classNameCard: "dataCard",
    classNameNav: "body__main-nav",
    selectorPrice: ".c-price-JS .c-price__value-JS",
    selectorTitle: "A.c-card-hotel-title-JS, A.c-card-coupon__promo-hotel-JS",
    classFlipCard: "c-flip-card-JS",
    selectorButton: ".booking-button-JS",
    classCardHotelPrice: "card-hotel-price-JS",
    selectorPromoButton: ".promo-button-JS",
    selectorResultList: ".result__list-JS",
    selectorLandingCollections: ".JS-offers-collections:not([data-cardType='card-compact-meeting'])",
    selectorBlocked: "blocked",
    classHeroSlider: ".heroslider",
    cssBreadCrumbSelector: ".cmp-breadcrumb",
    comparatorRelateds: false,
    url: null,
    loaded: false,
    blockedRequest: false,
    $container: null,
    $containerNav: null,
    data: [],
    queryParams: {},
    selectors: {},
    lowPrices: [0, 0],
    utagData: {
      product_id: [],
      product_brand: [],
      product_name: [],
      product_category: [],
      product_list: [],
      product_position: [],
      product_quantity: [],
      promo_creative: [],
      promo_id: [],
      promo_position: [],
      promo_value: [],
      promo_name: [],
      promo_value_type: [],
    },
    propInData: null,
    pendingFullData: 0,
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.distributor.beforeInit, true);
  },

  beforeInit: function () {
    const $component = document.getElementsByClassName(bcl.c.distributor.props.className);

    if (!$component || !$component[0]) {
      return;
    }

    if (!bcl.u.utag.props.enabledListener.includes("comparator")) {
      bcl.u.utag.props.enabledListener.push("comparator");
      bcl.u.utag.enableListener("comparator");
    }

    bcl.c.distributor.init();
  },

  init: function () {
    const $component = document.getElementsByClassName(bcl.c.distributor.props.className);
    if (!$component || !$component[0]) {
      return;
    }

    bcl.c.distributor.props.$container = $component[0];
    bcl.c.distributor.isComparator = bcl.c.distributor.props.$container?.dataset.cardtype === "card-comparator" || bcl.c.distributor.props.$container?.dataset.cardtype === "offers-collection";
    bcl.c.distributor.map.preInit();

    bcl.c.distributor.initPropInData();

    bcl.c.distributor.initSticky();

    bcl.c.distributor.getUrl();
    bcl.c.distributor.parseSelectors();
    bcl.c.distributor.parseQueryParams();

    bcl.c.distributor.initSubComponents();

    if (!bcl.c.distributor.props.filterDateActive) {
      bcl.c.distributor.queryParams();
    }

    bcl.c.distributor.requestFilterDate();
    bcl.c.distributor.unBlurMybPricesIfNeeded();

    if (document.querySelector('[data-cardType="card-compact-meeting"]')) {
      new MutationObserver((m, observer) => {
        const carouselSelector = ".c-card-hotel--compact-meeting .cmp-bhg-carousel";
        if (document.querySelectorAll(carouselSelector + ":not([class*='element-'])")) {
          bcl.c.carousel.init();
        }
        if (document.querySelectorAll(carouselSelector + "[class*='element-']")?.length === bcl.c.distributor.props.data.length) {
          observer.disconnect();
        }
      }).observe(document.querySelector(bcl.c.distributor.props.selectorResultList), { subtree: true, childList: true });
    }
  },

  unBlurMybPricesIfNeeded: function () {
    bcl.u.loadComponentByName("mybSwitcher");
    setTimeout(function () {
      bcl.c.mybSwitcher?.applyChanges("hotelComparator");
    }, 1000);
  },
  requestAsyncData: function () {
    const num = bcl.c.distributor.results.props.numberPagination;
    if (arguments && arguments.length > 0) {
      const argsPrev = Array.prototype.slice.call(arguments, 1);
      arguments[0].apply(null, argsPrev);
    }
    bcl.c.distributor.loadLazyCards();
    bcl.c.distributor.results.props.numberPagination = num;
    bcl.modal.init(bcl.c.distributor.props.$container);
    bcl.c.distributor.updateShowMore();
  },

  LoadSwitcher: async function () {
    await bcl.u.loadComponentByName("mybSwitcher");
    const itemsMybPrices = document.body.querySelectorAll("div." + bcl.c.mybSwitcher?.props?.classMyB + ":not(.hidden)");
    const $items = document.body.getElementsByClassName(bcl.c.mybSwitcher.props.cssSelector);
    if (itemsMybPrices.length > 0 || bcl.editMode) {
      if (bcl.c.mybSwitcher.props.PushItems.length > 0) {
        bcl.c.distributor.hideOrNot(bcl.c.mybSwitcher.props.PushItems, "remove");
      } else {
        bcl.c.distributor.hideOrNot($items, "remove");
      }
    } else {
      if (bcl.c.mybSwitcher.props.PushItems.length > 0) {
        bcl.c.distributor.hideOrNot(bcl.c.mybSwitcher.props.PushItems, "add");
      } else {
        bcl.c.distributor.hideOrNot($items, "add");
      }
    }
  },

  hideOrNot: function (list, type) {
    for (let i = 0; i < list.length; i++) {
      if (!bcl.u.containsClass(list[i], "hidden") && type == "add") {
        bcl.u.addClass(list[i], "hidden");
      } else if (bcl.u.containsClass(list[i], "hidden") && type == "remove") {
        bcl.u.removeClass(list[i], "hidden");
      }
    }
  },

  loadLazyCards: function () {
    const $cards = bcl.c.distributor.results.props.$container.querySelectorAll(`.${bcl.c.distributor.results.props.classNameContainerCard}.lazy-JS`);
    bcl.c.distributor.results.props.count = 1;
    window.loadedCards = Array($cards.length).fill(false);
    bcl.u.forEach($cards, bcl.c.distributor.loadLazyCard);
    bcl.c.distributor.results.props.cardsProcessed = null;
    if ($cards.length) {
      const populateAsyncCards = setInterval(function () {
        if (window.loadedCards && window.loadedCards.every && window.loadedCards.every((x) => x)) {
          clearInterval(populateAsyncCards);
          bcl.s.promoCountdown.utils.renderCountdown();
          delete window.loadedCards;
        }
      }, 500);
      setTimeout(() => {
        window.loadedCards = [true];
      }, 3000);
    }
  },

  loadLazyCard: function ($card, isMap) {
    if (bcl.u.containsClass($card, bcl.c.distributor.results.props.lazyCard) && (!bcl.u.containsClass($card, bcl.c.distributor.results.props.hidePagClassName) || isMap === true)) {
      let index = 0;
      let isOffer = false;
      bcl.u.forEach(bcl.c.distributor.props.data, function ($data) {
        if ($data.offer && $data.path + $data.offer.path === $card.dataset.cardId) {
          isOffer = true;
          index = bcl.c.distributor.requestLoadLazyCard($data, $card, index, isMap, isOffer);
        } else if (!$data.offer && (($card.closest(bcl.c.distributor.props.selectorLandingCollections) && $data.info.hotelId == $card.children[0].dataset.hotelId) || $data.info.hotelId == $card.children[1].dataset.hotelId)) {
          index = bcl.c.distributor.requestLoadLazyCard($data, $card, index, isMap, isOffer);
        } else if (window.loadedCards) {
          window.loadedCards[window.loadedCards.findIndex((x) => x === false)] = true;
        }
      });
      bcl.u.removeClass($card, bcl.c.distributor.results.props.lazyCard);
      bcl.u.removeBhgLoading($card);
    }
  },

  addedShowMyBPriceSelector: function (url) {
    url = bcl.u.includes(url, "html") ? url.replace(".html", ".showMyBPrice.html") : url;
    url = !bcl.u.includes(url, "html") && url.slice(-1) === "/" ? bcl.u.replaceLastCharacterWithSelector(url, "showMyBPrice") : url;

    return url;
  },

  requestLoadLazyCard: function ($data, $card, index, isMap, isOffer) {
    const type = bcl.c.distributor.props.$container.dataset.cardtype;
    let url = $data.urlPage.replace("json", type);
    const resultList = bcl.u.closest($card, bcl.c.distributor.props.selectorResultList);

    if (resultList && resultList.dataset && resultList.dataset.showmybprice && resultList.dataset.showmybprice === "true") {
      url = bcl.c.distributor.addedShowMyBPriceSelector(url);
    }

    if (isOffer) {
      url = bcl.c.distributor.urlOffersLazy($card, url);
    }
    bcl.ajax.getRequest(
      null,
      url,
      bcl.c.distributor.changeHtmlCard,
      {
        $card: $card,
        $index: index,
        $isMap: isMap,
        $isOffer: isOffer,
      },
      true,
    );
    return index++;
  },

  urlOffersLazy: function ($card, url) {
    const attributes = ["cardView", "hotelImage", "booking", "travel", "highlights", "noPriorityCoupon", "hideCouponCode", "deprioritizeCouponName", "from"];
    const $child = $card.closest(bcl.c.distributor.props.selectorLandingCollections) ? $card.children[0] : $card.children[1];
    const params = Object.assign({}, $child.dataset);
    Object.keys(params).forEach((k) => {
      if (!attributes.includes(k)) {
        delete params[k];
      }
    });
    params.wcmmode = "disabled";
    const paramsStr = [];
    Object.keys(params).forEach((k) => {
      if (params[k]) {
        paramsStr.push(`${k}=${params[k]}`);
      }
    });
    url += "?" + paramsStr.join("&");
    return url;
  },

  changeHtmlCard: async function (response, $params) {
    const $child = $params.$card.closest(bcl.c.distributor.props.selectorLandingCollections) ? $params.$card.children[0] : $params.$card.children[1];
    const price = $child.dataset.price;
    const priceTaxes = $child.dataset.priceTaxes;
    let $article = $params.$card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0];
    const indexCard = $article.dataset.index;
    let articleClassList = [];
    const divArticle = document.createElement("div"),
      $cardArticle = $params.$card.querySelector("article");
    divArticle.innerHTML = response.response;
    if (divArticle.querySelector("article")) {
      $cardArticle.innerHTML = divArticle.querySelector("article").innerHTML;
      articleClassList = divArticle.querySelector("article").classList;
    } else if (divArticle.querySelector("div.c-card-hotel-offer-JS")) {
      $cardArticle.innerHTML = divArticle.querySelector("div.c-card-hotel-offer-JS").innerHTML;
      articleClassList = divArticle.querySelector("div.c-card-hotel-offer-JS").classList;
    }

    bcl.u.forEach(articleClassList, function (currentClass) {
      !bcl.u.containsClass($cardArticle, currentClass) && bcl.u.addClass($cardArticle, currentClass);
    });

    bcl.c.distributor.changeIndexCard($params.$card, indexCard);
    bcl.c.distributor.initCard($params.$card, $params.$index);
    if (bcl.c.distributor.props.filterDateActive) {
      bcl.c.distributor.changePriceLazyCard($params.$card, price, priceTaxes);
    }
    bcl.c.fastbooking.initBookings($params.$card);
    bcl.s.currency.convertPrices($params.$card);
    bcl.c.distributor.activeButtonImage($params.$card);
    bcl.c.distributor.activeButtonFlipCard($params.$card);
    $article = $params.$card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0];
    const cardsData = bcl.c.distributor.getCardsData();
    let data = cardsData && cardsData.length > 0 ? cardsData[$article.dataset.index] : bcl.c.distributor.props.data[$article.dataset.index];
    if ($params.$isOffer) {
      data = bcl.c.distributor.props.data[$article.dataset.index];
    }
    if ($params.$isMap != null && $params.$isMap === true) {
      bcl.c.distributor.map.processCardMarker($params.$card, data);
    }

    if (!bcl.c.distributor.results.props.cardsProcessed) {
      bcl.c.distributor.results.props.cardsProcessed = bcl.c.distributor.results.props.$container.querySelectorAll("div." + bcl.c.distributor.results.props.classNameContainerCard + ":not(.hidden)");
    }

    if (bcl.c.distributor.results.props.count == 10 || bcl.c.distributor.results.props.count == bcl.c.distributor.results.props.cardsProcessed.length) {
      await bcl.c.distributor.LoadSwitcher();
      await bcl.u.loadComponentByName("mybTriggerBanner");
      const $containers = document.body.getElementsByClassName(bcl.c.mybTriggerBanner.props.classTrigger);
      bcl.u.forEach($containers, function ($container) {
        bcl.c.mybTriggerBanner.bannerSwitcher($container);
      });
    }
    bcl.c.distributor.results.props.count++;
    if ($params.$isOffer && window.loadedCards) {
      window.loadedCards[window.loadedCards.findIndex((x) => x === false)] = true;
    }
    if (bcl.s?.translateOnTheFly?.hasToTranslate()) {
      bcl.s.translateOnTheFly?.wrapper($params.$card);
    }
  },

  changeIndexCard: function ($card, $index) {
    const $article = $card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0];
    $article.dataset.index = $index;
  },

  activeButtonImage: function (card) {
    const button = card.querySelector("button." + bcl.modal.props.cssSelector);
    if (button) {
      button.addEventListener("click", bcl.modal.initModal);
      if (bcl.u.containsClass(document.body, "isAuthor")) {
        button.setAttribute("data-href", button.dataset.href + "?wcmmode=disabled");
      }
    }
  },

  activeButtonFlipCard: function (card) {
    const flipButton = card.querySelector(bcl.c.flipcard.props.selectorFlipCardDetailsButton);
    if (flipButton) {
      const flipCard = flipButton.closest(bcl.c.flipcard.props.selectorFlipCard);
      flipButton.addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--unflip");
        bcl.u.addClass(flipCard, "mod--flip");
      });
      flipCard.querySelector(bcl.c.flipcard.props.selectorFlipCardDetailsArea).addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--unflip");
        bcl.u.addClass(flipCard, "mod--flip");
      });
      flipCard.querySelector(bcl.c.flipcard.props.selectorFlipCardReverseRevealArea).addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--flip");
        bcl.u.addClass(flipCard, "mod--unflip");
      });
    }
  },

  changePriceLazyCard: function ($card, price, priceTaxes) {
    const $price = $card.getElementsByClassName(bcl.c.distributor.results.props.classPrice)[0];
    if (price > 0) {
      $price.innerText = price;
      $price.dataset.marketPrice = price;
    } else if (priceTaxes > 0) {
      $price.innerText = priceTaxes;
      $price.dataset.marketPrice = priceTaxes;
    } else {
      bcl.c.distributor.results.chooseContentNotAvailable($card);
    }
  },

  initPropInData: function () {
    bcl.c.distributor.props.propInData = bcl.c.distributor.props.$container.getAttribute("data-prop");
    bcl.c.distributor.props.messageNoAvailable = bcl.c.distributor.props.$container.getAttribute("data-text-no-available");
    bcl.c.distributor.props.messageNoAvailableUntil = bcl.c.distributor.initMessageNoAvailableUntil();
  },

  initMessageNoAvailableUntil: function () {
    const $el = bcl.c.distributor.props.$container.querySelector(".related-message-JS"),
      params = new URLSearchParams(window.location.search),
      date = params.get("openingDate");
    if (date) {
      try {
        const dateParams = date.split("-");
        if (dateParams && dateParams.length >= 3) {
          const months = $el.dataset.months.split(",");
          const monthIndex = parseInt(dateParams[1]) - 1;
          if (monthIndex < months.length) {
            const day = dateParams[2];
            const month = months[monthIndex];
            const message = bcl.c.distributor.props.$container.getAttribute("data-text-no-available-until");
            return message.replace("{0}", day).replace("{1}", month);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return bcl.c.distributor.props.$container.getAttribute("data-text-no-available");
  },

  initSticky: function () {
    bcl.c.distributor.props.$containerNav = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.props.classNameNav)[0];

    bcl.s.sticky.addStickyTop({
      $node: bcl.c.distributor.props.$containerNav,
      onlyMobile: true,
      callback: bcl.s.tooltip.isSticky,
      itemCallback: {
        attr: "tooltipsticky",
      },
    });
  },

  initSubComponents: function () {
    bcl.c.distributor.total.init();
    bcl.c.distributor.facets.init();
    bcl.c.distributor.comparator.init();
    bcl.c.distributor.results.init();
    bcl.c.distributor.order.init();
    bcl.c.distributor.buttons.init();
  },

  initCards: function (noFirst, utagVars) {
    const $cards = bcl.c.distributor.results.props.$container.querySelectorAll("." + bcl.c.distributor.results.props.classNameContainerCard + " , " + "." + bcl.c.distributor.results.props.classNameContainerCardHotelSurprise),
      $cardsSurprise = bcl.c.distributor.results.props.$containerSurprise?.querySelectorAll("." + bcl.c.distributor.results.props.classNameContainerCampaignCard);

    bcl.c.distributor.setPositionCard($cards);

    utagVars && bcl.c.distributor.initializeUtagVars();
    !noFirst && bcl.c.distributor.comparator.beforeInitCards($cards);
    bcl.u.forEach($cards, bcl.c.distributor.initCard);
    $cardsSurprise && bcl.u.forEach($cardsSurprise, bcl.c.distributor.initCard);
    utagVars && bcl.c.distributor.setLowPricesUtag();

    bcl.c.distributor.order.afterInitCards($cards);
    bcl.c.distributor.results.afterOffersInitCards && bcl.c.distributor.results.afterOffersInitCards($cards);

    bcl.c.distributor.results.afterInitCards && bcl.c.distributor.results.afterInitCards($cards);

    utagVars && bcl.c.distributor.removeListenerHotelComUtag();

    setTimeout(function () {
      bcl.c.distributor.results.moveBannerToPosition();
    }, 500);
  },
  initCard: function ($card, index) {
    const element = $card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0];
    const $article = element,
      $containerData = $article ? $article.getElementsByClassName(bcl.c.distributor.props.classNameCard)[0] : null;

    if (!$containerData) {
      return;
    }

    let data = null;
    if ($article.dataset.index) {
      data = bcl.c.distributor.props.data[$article.dataset.index];
    } else {
      data = $containerData.getAttribute("data-card");
      data = JSON.parse(data);
      bcl.c.distributor.props.data.push(data);
      $containerData.setAttribute("data-card", "");
      $article.setAttribute("data-index", bcl.c.distributor.props.data.length - 1);
    }

    if (!data) {
      return;
    }

    bcl.c.distributor.results.initCard($card, $article, data, index);
    if (bcl.u.containsClass($card, bcl.c.distributor.results.props.lazyCard)) {
      return;
    }
    bcl.c.distributor.comparator.processCard($card);

    if (bcl.u.utag.isEnabled()) {
      bcl.c.distributor.fetchUtag($article, data);
    }
  },

  setPositionCard: function ($cards) {
    bcl.u.forEach($cards, function ($card, index) {
      const $article = $card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0];
      if ($article) {
        $article.id = $article.id.replace("POSITION", index + 1);
      }
    });
  },

  getDataProcessMarkers: function ($cards) {
    bcl.u.forEach($cards, function ($card) {
      if (bcl.u.containsClass($card, "hidden")) {
        return;
      }

      const $article = $card.getElementsByClassName(bcl.c.distributor.results.props.classNameCard)[0],
        $containerData = $article ? $article.getElementsByClassName(bcl.c.distributor.props.classNameCard)[0] : null;

      if (!$containerData) {
        return;
      }
      const cardsData = bcl.c.distributor.getCardsData(),
        data = cardsData && cardsData.length > 0 && !$article.dataset.offerValue ? cardsData[$article.dataset.index] : bcl.c.distributor.props.data[$article.dataset.index];

      if (!data) {
        return;
      }

      bcl.c.distributor.map.processMarker($card, data);
    });
    bcl.u.removeBhgLoading();
  },

  initMarkerMaps: function (flag) {
    //TODO: reload img before clone card and create icon for googleMaps
    if (!flag) {
      bcl.c.distributor.map.props.marketsLoaded = true;
    }

    bcl.c.distributor.map.props.markers = [];
    bcl.c.distributor.map.props.markerClustering && bcl.c.distributor.map.props.markerClustering.clearMarkers();

    const $cards = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.classNameContainerCard);
    bcl.c.distributor.getDataProcessMarkers($cards);

    if (!flag) {
      bcl.c.distributor.map.generateInit();
      bcl.c.distributor.map.setFitBounds(bcl.c.distributor.map.props);
    }
  },

  evaluateValues: function (currentObj, obj) {
    return (currentObj.offer.type === "discount" && currentObj.offer.value_rate < obj.offer.value_rate) || (currentObj.offer.type === "value" && currentObj.offer.localLowPrice > obj.offer.localLowPrice);
  },

  //TODO: We will do refactor this funct
  pushDuplicates: function (currentObj, obj, idsDuplicates2) {
    if (currentObj && bcl.c.distributor.evaluateValues(currentObj, obj)) {
      const index = idsDuplicates2.indexOf(currentObj);
      idsDuplicates2.splice(index, 1);
      idsDuplicates2.push(obj);
    } else if (!currentObj) {
      idsDuplicates2.push(obj);
    }
  },
  //TODO: We will do refactor this funct
  getCardsData: function () {
    const dataCollection = bcl.c.distributor.props.data.slice(),
      idsDuplicates2 = [],
      idsWithoutDuplicates2 = [],
      finded = [];
    let idsCollection = dataCollection.slice();

    if (idsCollection.length > 0 && !idsCollection[0].offer) {
      return idsCollection;
    }

    bcl.u.forEach(dataCollection, function (obj) {
      if (
        idsWithoutDuplicates2.find(function (item) {
          return item.id === obj.id;
        })
      ) {
        const currentObj = idsDuplicates2.find(function (item) {
          return item.id === obj.id;
        });
        bcl.c.distributor.pushDuplicates(currentObj, obj, idsDuplicates2);
      } else {
        idsWithoutDuplicates2.push(obj);
      }
    });

    bcl.u.forEach(idsDuplicates2, function (obj) {
      dataCollection.find(function (item) {
        if (parseInt(item.id) === parseInt(obj.id)) {
          finded.push(item);
        }
      });
      idsCollection = bcl.c.distributor.removeAllDuplicatesId(idsCollection, obj);
    });

    return bcl.c.distributor.arrayUniqueId(idsDuplicates2.concat(idsCollection));
  },

  removeAllDuplicatesId: function (idsCollection, obj) {
    let i = 0;
    while (i < idsCollection.length) {
      if (idsCollection[i].id === obj.id) {
        idsCollection.splice(i, 1);
      } else {
        ++i;
      }
    }
    return idsCollection;
  },

  arrayUniqueId: function (array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i].id === a[j].id) {
          a.splice(j--, 1);
        }
      }
    }

    return a;
  },

  fetchUtag: function ($article, data) {
    if ($article) {
      bcl.c.distributor.offerFetchUtag($article, data);
      bcl.c.distributor.dataBasefetchUtag($article, data);

      bcl.c.distributor.priceFetchUtag($article, data);
      bcl.c.distributor.addClickInFetchUtag($article, data);
    }
  },

  //TODO: do this functions generic using an array with name of object to initialize
  initUtagProductVars: function () {
    bcl.c.distributor.props.utagData.product_id = [];
    bcl.c.distributor.props.utagData.product_list = [];
    bcl.c.distributor.props.utagData.product_position = [];
    bcl.c.distributor.props.utagData.product_name = [];
    bcl.c.distributor.props.utagData.product_brand = [];
    bcl.c.distributor.props.utagData.product_category = [];
    bcl.c.distributor.props.utagData.product_quantity = [];
  },

  initUtagPromoVars: function () {
    bcl.c.distributor.props.utagData.promo_id = [];
    bcl.c.distributor.props.utagData.promo_name = [];
    bcl.c.distributor.props.utagData.promo_value = [];
    bcl.c.distributor.props.utagData.promo_creative = [];
    bcl.c.distributor.props.utagData.promo_value_type = [];
    bcl.c.distributor.props.utagData.promo_position = [];
  },

  initializeUtagVars: function () {
    bcl.c.distributor.initUtagProductVars();
    bcl.c.distributor.initUtagPromoVars();
  },

  dataBasefetchUtag: function ($article, data) {
    bcl.c.distributor.props.utagData.product_id.push($article.getAttribute("data-hotel-id"));
    bcl.c.distributor.props.utagData.product_list.push(utag_data.ecommerce_action_field_list_gtag);

    bcl.c.distributor.props.utagData.product_position.push(bcl.c.distributor.props.utagData.product_position.length + 1 + "");

    bcl.c.distributor.props.utagData.product_name.push(data.info.nameTealium && data.info.nameTealium.toLowerCase());

    bcl.c.distributor.props.utagData.product_brand.push(data.info.brand && data.info.brand.brand_id && data.info.brand.brand_id.toLowerCase());
    bcl.c.distributor.props.utagData.product_category.push(data.info.category);
    bcl.c.distributor.props.utagData.product_quantity.push("1");
  },

  priceFetchUtag: function ($article, retryCount = 0) {
    const maxRetries = 10;
    const retryDelay = 1000;

    const $price = $article.querySelector(bcl.c.distributor.props.selectorPrice);
    const isDisabled = bcl.u.containsClass($article.querySelector(".container-image-JS"), "mod--img-disabled");

    if (!isDisabled && $price && (!bcl.c.distributor.props.lowPrices[0] || parseFloat($price.innerText) < bcl.c.distributor.props.lowPrices[0])) {
      if (!$price.innerText) {
        if (retryCount < maxRetries) {
          console.log(`Price not found, retrying in ${retryDelay / 1000} seconds... Attempt ${retryCount + 1}/${maxRetries}`);
          setTimeout(() => {
            bcl.c.distributor.priceFetchUtag($article, retryCount + 1);
          }, retryDelay);
        } else {
          console.log("Max retries reached. Price not found.");
        }
        return;
      }

      bcl.c.distributor.props.lowPrices[0] = parseFloat($price.innerText);

      const marketPrice = $price.dataset.marketPrice;
      if (marketPrice) {
        bcl.c.distributor.props.lowPrices[1] = marketPrice;
      }
    }
  },

  addClickInFetchUtag: function ($article, data) {
    let $button = $article.querySelector(bcl.c.distributor.props.selectorButton) || $article.querySelector(bcl.c.distributor.props.selectorPromoButton);

    if (!$button) {
      return;
    }

    // REMOVE OTHER EVENT LISTENERS
    const $clone = $button.cloneNode(true);
    $button.parentElement.replaceChild($clone, $button);
    $button = $clone;

    $button.addEventListener("click", function (e) {
      e.preventDefault();
      window.open(e.target.closest(".distributor-card-JS").querySelector(".c-card-hotel-title-JS").href, "_blank");
    });

    const $title = $article.querySelector(bcl.c.distributor.props.selectorTitle),
      isFlipCard = bcl.u.containsClass($article, bcl.c.distributor.props.classFlipCard);

    !isFlipCard &&
      $title &&
      $title.addEventListener("click", function () {
        bcl.u.utag.push(bcl.c.distributor.addDataInClickUTag(data, $article, "product_click", "select_product_content"));
      });
  },

  addDataInClickUTag: function (data, $article, action, actionGtag) {
    return {
      ecommerce_action: action,
      ecommerce_action_field_list_gtag: utag_data.ecommerce_action_field_list_gtag,
      ecommerce_action_gtag: actionGtag,
      product_id: [data.info.hotelId],
      product_brand: [data.info.brand && data.info.brand.brand_id && data.info.brand.brand_id.toLowerCase()],
      product_category: [data.info.category],
      product_name: [data.info.name && data.info.name.toLowerCase()],
      product_list: [utag_data.ecommerce_action_field_list_gtag],
      product_position: [parseInt($article.dataset.index) + 1 + ""],
    };
  },

  getUrl: function () {
    const $a = bcl.c.distributor.props.$container;

    if (!$a) {
      return;
    }

    bcl.c.distributor.props.url = $a.getAttribute("data-url");

    bcl.c.distributor.props.urlFilterDate = $a.getAttribute("data-urlfilterdate");

    bcl.c.distributor.props.urlAsync = $a.getAttribute("data-urlasync");

    bcl.c.distributor.props.urlFullData = !!$a.getAttribute("data-optionfulldata");
  },

  queryParams: function (send) {
    bcl.c.distributor.parseCorrectQueryParams();

    if (!send && Object.keys(bcl.c.distributor.props.queryParams).length === 0) {
      return;
    }

    bcl.c.distributor.requestAsyncData(bcl.c.distributor.request);
  },

  parseCorrectQueryParams: function () {
    for (const key in bcl.c.distributor.props.queryParams) {
      if (!bcl.c.distributor.facets.props.data[key]) {
        delete bcl.c.distributor.props.queryParams[key];
        continue;
      }

      for (const value in bcl.c.distributor.props.queryParams[key]) {
        if (!bcl.c.distributor.facets.props.data[key][value]) {
          delete bcl.c.distributor.facets.props.data[key][value];
        }
      }
    }
  },

  parseQueryParams: function () {
    let values = window.location.search;
    const obj = {};

    if (!values) {
      return;
    }

    values = decodeURIComponent(values);
    values = values.substring(1, values.length).split("&");

    for (let i = 0; i < values.length; i++) {
      const item = values[i].split("=");

      if (item.length <= 1) {
        continue;
      }

      const key = item[0],
        value = item[1];

      if (key == "startDate" || key == "endDate" || key == "title" || key == "noDispo" || key == "openingDate") {
        if (!bcl.c.distributor.props.filterDate) {
          bcl.c.distributor.props.filterDate = {};
        }

        if (key == "noDispo") {
          const keys = Object.keys(bcl.c.distributor.props.selectors);
          bcl.u.forEach(keys, function (key) {
            if (key.includes("rel-")) {
              bcl.c.distributor.props.hotelRelatedId = key.split("rel-")[1];
            }
          });

          bcl.c.distributor.props.comparatorRelateds = true;
        }

        if (key == "openingDate") {
          try {
            bcl.c.distributor.props.openingDate = new Date(value);
          } catch (e) {}
        }

        bcl.c.distributor.props.filterDate[key] = value;
      } else if (key != "wcmmode") {
        if (!obj[item[0]]) {
          obj[item[0]] = {};
        }

        const valueSplit = value.split(",");

        for (let n = 0; n < valueSplit.length; n++) {
          obj[key][valueSplit[n]] = true;
        }
      }
    }

    if (bcl.c.distributor.props.filterDate && bcl.c.distributor.props.filterDate.startDate && bcl.c.distributor.props.filterDate.endDate) {
      bcl.c.distributor.props.filterDateActive = true;
    }

    bcl.c.distributor.props.queryParams = obj;
  },

  parseSelectors: function () {
    let values = bcl.c.distributor.props.$container.dataset.selectors;
    const obj = {};

    if (!values) {
      return;
    }

    values = values.split(".");

    for (let i = 0; i < values.length; i++) {
      obj[values[i]] = true;
    }

    bcl.c.distributor.props.selectors = obj;
  },

  request: function (reset, data) {
    if (bcl.c.distributor.props.blockedRequest || !bcl.c.distributor.props.url) {
      return;
    }

    if (!data) {
      data = bcl.c.distributor.facets.getData();
      if (bcl.c.distributor.props.relatedComparatorLoaded) {
        data.sabre_id = bcl.c.distributor.props.relatedData.sabre_id;
      }
    }

    if (bcl.c.distributor.props.sabre_id && bcl.c.distributor.props.sabre_id.length > 0) {
      data.sabre_id = bcl.c.distributor.props.sabre_id;
      bcl.c.distributor.props.sabre_id = null;
    }

    bcl.u.addBhgLoading();
    if (bcl?.c?.distributor?.props?.$container?.dataset?.collectionFilter) {
      data.collectionFilter = bcl.c.distributor.props.$container.dataset.collectionFilter;
    }
    bcl.ajax.postRequest({
      data: data,
      url: bcl.c.distributor.props.url,
      callback: bcl.c.distributor.loadData,
      itemCallback: reset,
    });
  },

  loadData: function (request) {
    const data = JSON.parse(request.response);

    if (!data) {
      return;
    }

    bcl.c.distributor.total.load(data.total);
    bcl.c.distributor.facets.load(data);
    bcl.c.distributor.results.load(data.results);
    bcl.c.distributor.initMarkerMaps();

    data.pathPage && bcl.c.distributor.requestDestination(data.pathPage);

    !data.pathPage && bcl.u.removeBhgLoading();
  },

  requestDestination: function (urlDestination) {
    bcl.ajax.getRequest(null, urlDestination, bcl.c.distributor.customInfoDestination);
  },

  customInfoDestination: function (request) {
    if (request.response) {
      const newDiv = document.createElement("div");
      newDiv.innerHTML = request.response;
      bcl.c.distributor.changeHtml(newDiv, bcl.c.distributor.props.classHeroSlider);
      bcl.c.heroslider.init();
    }
    bcl.u.removeBhgLoading();
  },

  changeHtml: function (newDiv, className) {
    const custom = newDiv.querySelector(className);
    const normal = document.querySelector(className);
    normal.innerHTML = custom.innerHTML;
    if (custom && newDiv?.querySelector(bcl.c.distributor.props.cssBreadCrumbSelector) && !normal?.querySelector(bcl.c.distributor.props.cssBreadCrumbSelector) && !normal?.closest("main")?.querySelector(bcl.c.distributor.props.cssBreadCrumbSelector)) {
      bcl.c?.heroslider?.processInjectBreadCrumb(normal, newDiv);
    }
  },

  requestFilterDate: function (flag) {
    if (!bcl.c.distributor.props.filterDateActive || !bcl.c.distributor.props.data || bcl.c.distributor.props.data.length == 0) {
      if (sessionStorage.getItem("destinationLabel")) {
        bcl.c.distributor.setParameterInFB();
      }
      return;
    }

    bcl.u.utag.enableListener();

    if (flag) {
      bcl.c.distributor.props.filterDateActive = false;
    }
    bcl.c.distributor.setParameterInFB();

    const data = bcl.c.distributor.getParametersFilterData();

    bcl.c.distributor.addSpinners();

    if (data.id && data.id != "") {
      bcl.u.addBhgLoading();
      bcl.s.tooltip.closeActive();

      bcl.ajax.postRequest({
        data: data,
        url: bcl.c.distributor.props.urlFilterDate,
        callback: bcl.c.distributor.loadDataFilterDate,
      });
    }

    return true;
  },

  setParameterInFB: function () {
    if (!bcl.c.distributor.props.filterDate) {
      bcl.c.distributor.props.filterDate = {};
    }
    const selectors = Object.keys(bcl.c.distributor.props.selectors);
    const label = bcl.c.distributor?.props?.filterDate?.title ? bcl.c.distributor.props.filterDate.title : sessionStorage.getItem("destinationLabel");
    if (!selectors.length || !label) {
      return;
    }

    bcl.c.fastbooking.destination.props.used = true;
    bcl.c.fastbooking.calendar.props.used = true;

    bcl.c.fastbooking.calendar.updateInputs({
      startDate: bcl.c.distributor.props.filterDate.startDate,
      endDate: bcl.c.distributor.props.filterDate.endDate,
    });

    const id = selectors[0];
    const type = "group";

    const destinationData = {
      destination: {
        id: id,
        label: label,
        type: type,
      },
    };

    if (selectors[0].indexOf("rel-") !== -1) {
      destinationData.destination.id = selectors[0].replace("rel-", "");
      destinationData.destination.type = "hotel";
      destinationData.destination.data = {};
      destinationData.destination.data.openingDate = bcl.c.distributor.props.filterDate.openingDate;
      destinationData.destination.data.noDispo = bcl.c.distributor.props.filterDate.noDispo === "true";
    }

    bcl.c.fastbooking.setData(destinationData, true);
  },

  getParametersFilterData: function () {
    return {
      arrive: bcl.c.distributor.props.filterDate.startDate,
      depart: bcl.c.distributor.props.filterDate.endDate,
      id: bcl.c.distributor.getIds(),
    };
  },

  getIds: function () {
    let ids = "";
    for (let i = 0; i < bcl.c.distributor.props.data.length; i++) {
      if (!bcl.c.distributor.props.data[i].info.noCalendar) {
        if (ids != "") {
          ids += ",";
        }
        ids += bcl.c.distributor.props.data[i].info.hotelId;
      }
    }
    return ids;
  },

  setLowPricesUtag: function () {
    const lowPriceFormated = bcl.s.currency.formatPrice(bcl.c.distributor.props.lowPrices[0]).split(".").join("");

    bcl.c.distributor.props.utagData.booking_price = "" + lowPriceFormated;
    bcl.c.distributor.props.utagData.booking_price_exchange = "" + bcl.s.currency.setPriceRounded(bcl.s.currency.props.currency.rate, lowPriceFormated);

    bcl.u.utag.add(bcl.c.distributor.props.utagData);
  },

  removeListenerHotelComUtag: function () {
    bcl.u.utag.removeListener("hotel-comparator");
  },

  loadDataFilterDate: function (request) {
    if (!request || !request.response) {
      // Error petición
      bcl.c.distributor.results.showByData(bcl.c.distributor.props.data);
      bcl.u.removeBhgLoading();
      return;
    }

    const data = JSON.parse(request.response);

    if (!data || data.errorApi) {
      bcl.c.distributor.results.showByData(bcl.c.distributor.props.data);
      bcl.u.removeBhgLoading();
      bcl.c.distributor.clearSpinners();
      return;
    }
    const sabre_id = [],
      dataArr = Object.entries(data);
    bcl.c.distributor.props.lowPrices = [10000000000, 10000000000];

    //for (let key in Object.entries(data))
    for (let x = 0; x < dataArr.length; x++) {
      bcl.c.distributor.results.loadDataFilterDateInCard(dataArr[x][1], dataArr[x][0]);

      for (let i = 0; i < bcl.c.distributor.props.data.length; i++) {
        if ((dataArr[x][1].hotelPriceFromMap && dataArr[x][1].hotelPriceFromMap[dataArr[x][0]]) || (dataArr[x][1].myBarceloHotelPriceFrom && dataArr[x][1].myBarceloHotelPriceFrom[dataArr[x][0]])) {
          if (bcl.c.distributor.props.data[i].info.hotelId == dataArr[x][0]) {
            bcl.c.distributor.updateObjPrices(data, i, dataArr[x][0]);
          }
        }
      }

      if ((dataArr[x][1].hotelPriceFromMap && dataArr[x][1].hotelPriceFromMap[dataArr[x][0]]) || (dataArr[x][1].myBarceloHotelPriceFrom && dataArr[x][1].myBarceloHotelPriceFrom[dataArr[x][0]])) {
        sabre_id.push("" + dataArr[x][0]);
      }
    }

    if (bcl.c.distributor.props.lowPrices) {
      bcl.c.distributor.setLowPricesUtag();

      bcl.c.distributor.removeListenerHotelComUtag();
    }

    if (bcl.c.distributor.props.comparatorRelateds) {
      bcl.c.distributor.relatedComparator();
    }

    bcl.u.utag.add({ search_availability: sabre_id.length > 0 ? "1" : "0" });
    bcl.u.utag.removeListener();

    setTimeout(function () {
      bcl.u.triggerEvent(bcl.c.distributor.results.props.$container.parentNode, "cmp-image-global-redraw");
    }, 500);

    if (bcl.c.distributor.order.props.data == "info.hotelLocalPriceFormatted") {
      bcl.c.distributor.order.process(true);
    }

    bcl.c.distributor.clearSpinners();

    bcl.modal.init(bcl.c.distributor.results.props.$container.parentNode);
    bcl.u.removeBhgLoading();
  },

  //HOTEL RELATED IMPROVEMENTS
  relatedComparator: function () {
    //There Are relates with dispo
    if (bcl.c.distributor.props.relatedComparatorLoaded) {
      return;
    }

    bcl.c.distributor.props.relatedComparatorLoaded = true;
    bcl.c.distributor.props.relatedData = bcl.c.distributor.facets.getData();

    const keys = Object.keys(bcl.c.distributor.props.selectors);
    let numOfHotels = 0;
    bcl.u.forEach(keys, function (key) {
      if (key.includes("rel-")) {
        const hotelsResults = bcl.c.distributor.props.$container.querySelectorAll(".result__list-item:not(." + bcl.c.distributor.props.classNameHidden + ")");

        bcl.u.forEach(hotelsResults, function (hotelCard) {
          bcl.c.distributor.setHotelsRelatedsData(bcl.c.distributor.props.relatedData, hotelCard.dataset.id);
          numOfHotels++;
        });
      }
    });

    bcl.c.distributor.total.props.$container.innerText = numOfHotels;

    if (!bcl.c.distributor.map.props.loaded) {
      document.addEventListener(bcl.map.props.nameInitEvent, function () {
        bcl.c.distributor.map.init();
        bcl.c.distributor.request(null, bcl.c.distributor.props.relatedData);
      });
      bcl.map.init();
    } else {
      bcl.c.distributor.request(null, bcl.c.distributor.props.relatedData);
    }
  },

  setHotelsRelatedsData: function (data, hotelId) {
    if (!data.sabre_id) {
      data.sabre_id = hotelId;
    } else {
      data.sabre_id += "," + hotelId;
    }
  },

  updateObjPrices: function (data, i, key) {
    let price, myBarceloPrice;

    if (data[key].hotelPriceFromMap[key].Amount === 0) {
      price = data[key].hotelPriceFromMap[key].AmountWithTaxesFees;
    } else {
      price = data[key].hotelPriceFromMap[key].Amount;
    }

    if (data[key].myBarceloHotelPriceFrom[key].Amount === 0) {
      myBarceloPrice = data[key].myBarceloHotelPriceFrom[key].AmountWithTaxesFees;
    } else {
      myBarceloPrice = data[key].myBarceloHotelPriceFrom[key].Amount;
    }

    bcl.c.distributor.props.data[i].info.hotelLocalPrice = price;
    bcl.c.distributor.props.data[i].info.hotelLocalPriceFormatted = price.toString();
    bcl.c.distributor.props.data[i].info.hotelLocalMybPrice = myBarceloPrice;
  },

  goToNode: function ($node) {
    const $body = document.querySelector("html,body");
    let top = $body.scrollTop + 3;

    if ($node) {
      top = bcl.c.distributor.getScrollTop($node, $body);
    }

    bcl.u.goToWithScroll({
      top: top,
    });
  },

  getScrollTop: function ($node, $body) {
    const rect = $node.getBoundingClientRect();
    return rect.top + $body.scrollTop;
  },

  isLoadFullData: function ($element, $card, loading) {
    const index = $card.dataset.index;

    if (!bcl.c.distributor.props.urlFullData || !bcl.c.distributor.props.data || !bcl.c.distributor.props.data[index] || bcl.c.distributor.props.data[index].loadData) {
      return true;
    }

    if (loading) {
      bcl.u.addBhgLoading();
    }

    const data = {
      id: $element.dataset.id,
      $node: $element,
      index: index,
      url: bcl.c.distributor.props.data[index].urlPage,
    };

    if (bcl.u.containsClass($card, "c-card-hotel-offer-JS")) {
      data.id = $element.dataset.id;
      data.isOffer = true;
      data.urlSuffix = bcl.c.distributor.props.data[index].offer.path;
    }

    bcl.c.distributor.requestFullData(data);
  },

  requestFullData: function (obj) {
    if (!bcl.c.distributor.props.urlFullData || !obj.url) {
      return;
    }

    bcl.c.distributor.props.pendingFullData++;

    if (bcl.u.containsClass(document.body, "isAuthor")) {
      obj.url += "?wcmmode=disabled";
    }

    bcl.ajax.getRequest(null, obj.url, bcl.c.distributor.loadFullData, obj);
  },

  loadFullData: function (request, obj) {
    bcl.c.distributor.props.pendingFullData--;

    if (!request || !request.response) {
      return;
    }

    let response = request.response;

    if (bcl.u.containsClass(document.body, "isAuthor")) {
      response = response.split("\n")[0];
    }

    const data = JSON.parse(response);

    if (!data) {
      return;
    }

    const index = obj.index;

    bcl.c.distributor.props.data[index] = bcl.u.deepExtend(bcl.c.distributor.props.data[index], data);
    bcl.c.distributor.props.data[index].loadData = true;

    bcl.c.distributor.comparator.updateButton();
    bcl.u.triggerEvent(document, "comparator-fulldata-loaded");

    if (obj.isOffer) {
      bcl.c.distributor.results.openDetailAfterLoadFullData(index);
    }
  },

  clearSpinners: function () {
    const prices = document.querySelectorAll("div" + bcl.c.distributor.props.selectorPrice);
    bcl.u.forEach(prices, function ($price) {
      if (!bcl.u.containsClass($price, bcl.c.distributor.props.selectorBlocked)) {
        $price.style.color = "";
        bcl.u.removeSpinner($price);
      }
    });
  },

  addSpinners: function () {
    const prices = document.querySelectorAll("div" + bcl.c.distributor.props.selectorPrice);
    if (prices && prices.length > 0) {
      const color = window.getComputedStyle(prices[0].parentNode, null).getPropertyValue("background-color");
      bcl.u.forEach(prices, function ($price) {
        $price.style.color = color;
        bcl.u.addClass($price, bcl.c.distributor.props.selectorBlocked);
        bcl.u.addSpinner($price);
      });
    }
  },

  updateShowMore: function () {
    if (bcl.c.distributor.isComparator) {
      const $comparator = bcl.c.distributor.props.$container;
      $comparator?.querySelector(".show-more-JS")?.parentElement.classList[$comparator.querySelector(".result__list-item.mod--hidePagination:not(.hidden)") ? "remove" : "add"]("hidden");
    }
  },
};

bcl.c.distributor.map = {
  props: {
    className: "result__map",
    classNameContainerMap: "result__map-container",
    nameLatAttr: "data-location-lat",
    nameLngAttr: "data-location-lng",
    classNameMybDistributor: "c-card-hotel-my-barcelo-price",
    $container: null,
    latDefault: 36.003204,
    lngDefault: -7.976789,
    markers: [],
    markersShow: [],
    markersPopups: [],
    markerClustering: undefined,
  },

  preInit: function () {
    bcl.c.distributor.map.props.$container = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.map.props.className)[0];
    bcl.c.distributor.map.props.$containerMap = bcl.c.distributor.map.props.$container?.getElementsByClassName(bcl.c.distributor.map.props.classNameContainerMap)[0];
  },

  init: function (comparator) {
    if (!bcl.c.distributor.props.$container) {
      bcl.u.utag.removeListener();
      return;
    }

    if (!bcl.c.distributor.props.pendingDataAsync) {
      bcl.c.distributor.map.generateMap(comparator);
      bcl.c.distributor.map.generateInit();
    }
  },

  handleInit: function (callback) {
    document.addEventListener(bcl.map.props.nameInitEvent, callback);
    bcl.map.init();
  },

  generateMap: function (comparator) {
    bcl.c.distributor.map.createMap(bcl.c.distributor.map.props);
    if (!comparator) {
      bcl.c.distributor.map.props.loaded = true;
    }
  },

  generateInit: function () {
    bcl.u.utag.removeListener();

    bcl.c.distributor.map.addMarkersClustering(bcl.c.distributor.map.props);

    bcl.c.distributor.map.setFitBounds(bcl.c.distributor.map.props);
  },

  createMap: function (props) {
    const mobileFlagTemp = bcl.u.mobile.isMobile();
    const mapProperties = {
      center: new google.maps.LatLng(props.latDefault, props.lngDefault),
      zoom: 2,
      minZoom: 1,
      mapTypeControl: false,
      streetViewControl: false,
      styles: bcl.map.props.mapStyles.defaultStyle,
    };

    if (mobileFlagTemp) {
      mapProperties.zoomControlOptions = {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      };

      mapProperties.fullscreenControlOptions = {
        position: google.maps.ControlPosition.LEFT_TOP,
      };
    }

    props.map = new google.maps.Map(props.$containerMap, mapProperties);
    props.bounds = new google.maps.LatLngBounds();
  },

  setFitBounds: function (props) {
    if (!props.bounds) {
      return;
    }
    setTimeout(function () {
      props.map.fitBounds(props.bounds);
      if (props.map.zoom > 10) {
        props.map.setZoom(10);
        return;
      }

      if (props.map.zoom < 2) {
        props.map.setZoom(2);
        return;
      }
    }, 100);
  },

  checkLocation: function (item) {
    if (!item.info || !item.info.latitude || -85 > item.info.latitude || item.info.latitude > 85 || !item.info.longitude || -180 > item.info.longitude || item.info.longitude > 180) {
      return false;
    }

    return true;
  },

  createMarker: function (props, label, path, item) {
    const position = new google.maps.LatLng(item.info.latitude, item.info.longitude);

    const size = label.length * 16;

    props.markers[path] = new google.maps.Marker({
      map: props.map,
      position: position,
      label: { text: label, color: "white", fontWeight: "bold", fontSize: "16px" },
      icon: new google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/map_icon_price2.svg", null, null, null, new google.maps.Size(size, 48)),
    });

    props.markers[path].hotelId = item.info.hotelId;

    props.bounds.extend(position);
  },

  processMarker: function ($card, item) {
    if (!bcl.c.distributor.map.checkLocation(item)) {
      return;
    }

    let path = $card.getAttribute(bcl.c.distributor.results.props.nameAttrCard);

    if (item.offer) {
      path = item.path;
    }

    if (bcl.c.distributor.map.props.marketsLoaded) {
      bcl.c.distributor.map.createMarker(bcl.c.distributor.map.props, bcl.c.distributor.map.generateLabel(item), path, item);
    }

    if (typeof google !== "undefined") {
      google.maps.event.addListener(bcl.c.distributor.map.props.markers[path], "click", function () {
        if (bcl.u.containsClass($card, bcl.c.distributor.results.props.lazyCard)) {
          bcl.c.distributor.loadLazyCard($card, true);
        } else {
          bcl.c.distributor.map.processCardMarker($card, item);
        }
      });
    }
  },

  processCardMarker: function ($card, item) {
    let marker = bcl.c.distributor.map.props.markers[item.path];
    if (item.offer) {
      marker = bcl.c.distributor.map.props.markers[item.path];
    }
    bcl.c.distributor.map.props.map.panTo(marker.getPosition());
    bcl.c.distributor.map.hidePopups();

    const image = $card.querySelector(".cmp-image img");
    if (image) {
      bcl.u.triggerEvent(image, "cmp-image-global-redraw-all");
    }
    //recalculated Card when click
    const $cardCloned = $card.closest(bcl.c.distributor.props.selectorLandingCollections) ? $card.children[0].cloneNode(true) : $card.children[1].cloneNode(true);
    if (item.offer) {
      bcl.c.distributor.map.updateLabelMarker(null, marker, item);
    } else {
      bcl.c.distributor.map.updateLabelMarker("currency", marker, item);
    }
    const $closeBtn = $cardCloned?.querySelector(".c-close-btn");
    if ($closeBtn) {
      bcl.u.removeClass($closeBtn, "hidden");
      $closeBtn.addEventListener("click", function () {
        bcl.c.distributor.map.props.markersPopups[item.path].setMap(null);
      });
    }

    setTimeout(function () {
      bcl.c.fastbooking.initBookings($cardCloned);
      bcl.c.distributor.results.initOfferCard($card, $cardCloned, item);

      const Popup = bcl.map.popupClass();
      bcl.c.distributor.map.props.markersPopups[item.path] = new Popup(marker.getPosition(), $cardCloned);

      bcl.c.distributor.map.props.markersPopups[item.path].setMap(bcl.c.distributor.map.props.map);

      setTimeout(function () {
        bcl.modal.init();
        bcl.c.distributor.clearSpinners();
        bcl.s.currency.convertPrices();
        if ($card.querySelector(".carouselMeetingRoom-JS")) {
          bcl.c.carousel.init();
        }
      }, 100);
    }, 500);
  },

  updateLabelMarker: function (fromCurrency, marker, item) {
    if (fromCurrency != undefined) {
      if (!marker) {
        if (item.offer) {
          marker = markers[item.path];
        }
      }
      if (marker) {
        bcl.c.distributor.map.updateLabelMarketMapLogic(marker);
      }
    } else {
      if (marker) {
        const price = bcl.c.distributor.map.generateLabel(item);
        const markerCopyLabel = marker.label;
        markerCopyLabel.text = item.offer ? price : price + bcl.u.getCurrency();
        marker.set("label", markerCopyLabel);
      }
    }
  },

  updateLabelMarketMapLogic: function (marker) {
    const mapParent = bcl.c.distributor.map.props.$container.parentNode;
    const cardWithPrice = mapParent.querySelectorAll('[data-hotel-id="' + marker.hotelId + '"]');
    let price;
    if (cardWithPrice) {
      bcl.u.forEach(cardWithPrice, function (card) {
        if (!bcl.u.containsClass(card, "popup-bubble")) {
          if (card.querySelector(".c-price__value-JS:not(.mod--strike)")) {
            price = card.querySelector(".c-price__value-JS:not(.mod--strike)").textContent;
            price = Number(price.replace(".", "").replace(",", ""));
          } else {
            let priceDefault;
            if (bcl.u.containsClass(card.parentNode, bcl.c.distributor.results.props.lazyCard) && bcl.c.distributor.props.filterDateActive) {
              priceDefault = card.dataset.price > 0 ? card.dataset.price : card.dataset.priceTaxes;
            } else {
              const cardsData = bcl.c.distributor.getCardsData(),
                data = cardsData && cardsData.length > 0 && !card.dataset.offerValue ? cardsData[card.dataset.index] : bcl.c.distributor.props.data[card.dataset.index];
              priceDefault = data.info.hotelLocalMybPrice;
            }
            price = Math.ceil(priceDefault * bcl.s.currency.props.currency.rate);
          }
          if (Math.ceil(price) !== 0 && !card.querySelector(".c-card-hotel__content-not-available")) {
            price = price + bcl.u.getCurrency();
          } else {
            price = card.getAttribute("data-not-available-message");
          }
        }
      });
      const markerCopyLabel = marker.label;
      markerCopyLabel.text = price;
      marker.set("label", markerCopyLabel);
      let size = price.length * 16;
      if (size === 0) {
        size = 48;
      }
      marker.set("icon", new google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/map_icon_price2.svg", null, null, null, new google.maps.Size(size, 48)));
    }
  },

  generateLabel: function (item) {
    const card = document.querySelector('article[data-hotel-id="' + item.info.hotelId + '"]') || document.querySelector('div[data-hotel-id="' + item.info.hotelId + '"]');

    const myb = card?.classList?.contains(bcl.c.distributor.map.props.classNameMybDistributor);

    let price = myb && item?.info?.hotelLocalMybPrice ? item.info.hotelLocalMybPrice : item.info.hotelLocalPrice;
    let convertPrice = true;
    if (card) {
      if (card.querySelector(".c-price__value-JS:not(.mod--strike)")) {
        price = card.querySelector(".c-price__value-JS:not(.mod--strike)").textContent;
        price = Number(price.replace(".", "").replace(",", ""));
        convertPrice = false;
      }
      if (bcl.u.containsClass(card.parentNode, bcl.c.distributor.results.props.lazyCard) && bcl.c.distributor.props.filterDateActive) {
        price = card.dataset.price > 0 ? card.dataset.price : card.dataset.priceTaxes;
      }
    }
    if (item.offer && card) {
      const discount = card.querySelector(".card-hotel-discount-JS");
      if (discount) {
        return discount.textContent;
      } else if (card.dataset.offerValue) {
        return Math.ceil(card.dataset.offerValue) + "%";
      }
    }

    if (bcl.s.currency.props.currency.current != bcl.s.currency.props.currency.market && convertPrice) {
      price = price * bcl.s.currency.props.currency.rate;
    }

    if (Math.ceil(price) !== 0) {
      return Math.ceil(price) + bcl.u.getCurrency();
    } else {
      if (card) {
        return card.getAttribute("data-not-available-message");
      }
    }
    return " ";
  },

  hideAll: function () {
    bcl.c.distributor.map.props.markersShow = [];

    for (const key in bcl.c.distributor.map.props.markers) {
      bcl.c.distributor.map.props.markers[key].setMap(null);
      if (bcl.c.distributor.map.props.markersPopups[key] != null) {
        bcl.c.distributor.map.props.markersPopups[key].setMap(null);
      }
    }

    if (typeof google !== "undefined") {
      bcl.c.distributor.map.props.bounds = new google.maps.LatLngBounds();
    }
  },

  hidePopups: function () {
    for (const key in bcl.c.distributor.map.props.markersPopups) {
      bcl.c.distributor.map.props.markersPopups[key].setMap(null);
    }
  },

  showByData: function () {
    if (bcl.c.distributor.map.props.markerClustering != undefined) {
      bcl.c.distributor.map.props.markerClustering.clearMarkers();
      bcl.c.distributor.map.props.markerClustering.addMarkers(bcl.c.distributor.map.props.markersShow, false);
      bcl.c.distributor.map.setFitBounds(bcl.c.distributor.map.props);
    }
  },

  showByItem: function (item) {
    if (!item) {
      return;
    }

    const value = bcl.u.getInData(item, bcl.c.distributor.props.propInData);

    if (!value) {
      return;
    }

    const marker = bcl.c.distributor.map.props.markers[value];

    if (marker) {
      marker.setMap(bcl.c.distributor.map.props.map);
      bcl.c.distributor.map.props.bounds.extend(marker.getPosition());
      bcl.c.distributor.map.props.markersShow.push(marker);
    }
  },

  callback: function ($card) {
    if ($card) {
      bcl.c.distributor.comparator.processCard($card);

      const items = $card.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonRoom);
      bcl.u.forEach(items, function ($item) {
        $item.dataset.load = "";
      });

      bcl.c.fastbooking.initBookings($card);
    }
  },

  addMarkersClustering: function (props) {
    const svg = "/content/dam/barcelo/commons/icons/statics/poi_group.svg",
      textColor = "#ffffff",
      textSize = 14,
      mcOptions = {
        styles: [
          {
            height: 29,
            url: svg,
            width: 39,
            textColor: textColor,
            textSize: textSize,
          },
        ],
      };

    const markersList = [];

    for (const key in props.markers) {
      markersList.push(props.markers[key]);
    }

    props.markerClustering = new MarkerClusterer(props.map, markersList, mcOptions);
  },
};

bcl.c.distributor.commons = {};
bcl.preInit.push(bcl.c.distributor.preInit);
