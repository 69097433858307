bcl.c.distributor.total = {
  props: {
    className: "main-nav__total",
    $container: null,
  },

  init: function () {
    bcl.c.distributor.total.props.$container = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.total.props.className)[0];
  },

  load: function (total) {
    if (!isNaN(total)) {
      document.querySelector("." + bcl.c.distributor.total.props.className).innerText = total;
    }
  },
};

bcl.c.distributor.order = {
  props: {
    className: "main-nav__select",
    classNameSelect: "input-order",
    classNamePrice: "c-price-JS",
    classNameScore: "ta__score",
    data: "relevant",
    $container: null,
    $select: null,
  },

  init: function () {
    bcl.c.distributor.order.props.$container = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.order.props.className)[0];

    if (bcl.c.distributor.order.props.$container) {
      bcl.c.distributor.order.props.$select = bcl.c.distributor.order.props.$container.getElementsByClassName(bcl.c.distributor.order.props.classNameSelect)[0];

      if (bcl.c.distributor.order.props.$select) {
        bcl.c.distributor.order.props.active = bcl.c.distributor.order.props.$select.dataset.activeoreder;
        bcl.c.distributor.order.props.$select.addEventListener("change", bcl.c.distributor.order.click);
      }
    }
  },

  afterInitCards: function () {
    if (bcl.c.distributor.order.props.active) {
      bcl.c.distributor.order.process(bcl.c.distributor.order.props.active);
    }
  },

  click: function () {
    bcl.c.distributor.requestAsyncData(bcl.c.distributor.order.process);
  },

  process: function (fromDates) {
    const value = bcl.c.distributor.order.props.$select.value;

    if (bcl.c.distributor.order.props.data == value && !fromDates) {
      return;
    }

    bcl.c.distributor.order.props.data = value;

    bcl.c.distributor.props.data.sort(bcl.c.distributor.order.dynamicSort(value));

    bcl.c.distributor.results.updateOrder(bcl.c.distributor.props.data);
    bcl.u.removeClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);

    setTimeout(function () {
      bcl.c.distributor.results.moveBannerToPosition();
    }, 500);
    bcl.c.distributor.updateShowMore();
  },

  dynamicSort: function (property) {
    let sortOrder = 1;
    let productUpEnabled = false;
    const comparatorComponent = document.getElementsByClassName("c-hotels-comparator");
    if (comparatorComponent) {
      const productUpData = comparatorComponent[0].dataset.sortMode;
      productUpEnabled = productUpData == "PRODUCTSUP" && property == "-ranking";
    }

    if (property[0] === "-") {
      sortOrder = -1;
      if (productUpEnabled) {
        sortOrder = 1;
        property = "externalRanking";
      } else {
        property = property.substr(1);
        if (property === "ranking") {
          sortOrder = 1;
        }
      }
    }

    return function (a, b) {
      let getA = bcl.u.getInData(a, property),
        getB = bcl.u.getInData(b, property),
        result;
      const isPriceSorting = property.toLowerCase().includes("price");

      if (typeof getA === "string") {
        if (getA.match(/[a-z]/i)) {
          getA = new Date(getA).getTime();
          getB = new Date(getB).getTime();
        } else {
          if (isPriceSorting) {
            if (getA <= 0) {
              getA = (Number.MAX_SAFE_INTEGER * sortOrder).toString();
            }
            if (getB <= 0) {
              getB = (Number.MAX_SAFE_INTEGER * sortOrder).toString();
            }
          }
          getA = parseInt(getA.split(".").join(""));
          getB = parseInt(getB.split(".").join(""));
        }
      }

      if (typeof getA === "number") {
        getA = parseFloat(getA);
        getB = parseFloat(getB);

        if (isPriceSorting) {
          if (getA <= 0) {
            getA = Number.MAX_SAFE_INTEGER * sortOrder;
          }

          if (getB <= 0) {
            getB = Number.MAX_SAFE_INTEGER * sortOrder;
          }
        }
      }

      if (typeof getA == "boolean") {
        result = getA == getB ? 0 : getA ? -1 : 1;
      } else {
        result = getA < getB ? -1 : getA > getB ? 1 : 0;
      }

      return result * sortOrder;
    };
  },

  get: function (obj, path) {
    // Cache the current object
    let current = obj;
    path = path.split(".");
    // For each item in the path, dig into the object
    for (let i = 0; i < path.length; i++) {
      // If the item isn't found, return the default (or null)
      if (!current[path[i]] && isNaN(current[path[i]])) {
        return null;
      }

      // Otherwise, update the current  value
      current = current[path[i]];
    }

    return current;
  },
};

bcl.c.distributor.results = {
  props: {
    className: "result__list",
    classNameContainerCard: "result__list-item",
    classNameContainerCampaignCard: "c-campaign-list__col-JS",
    classNameContainerCardHotelSurprise: "hotelSurprise3Position",
    classNameCard: "distributor-card-JS",
    classNameMybDistributor: "c-card-hotel-my-barcelo-price",
    classNameContainerSurprise: "campaign-list-surprise-JS",
    paginationClassName: "pagination-JS",
    nameAttrCard: "data-card-id",
    hidePagClassName: "mod--hidePagination",
    lazyCard: "lazy-JS",
    classPrice: "c-price__value-JS",
    hotelSurprise3Position: "hotelSurprise3Position",
    offerCollectionsSelector: "JS-offers-collections",
    numberPagination: 10,
    numberPaginationCONST: 10,
    hidePagination: false,
    $container: null,
    $containerSurprise: null,
    moreInfoClicked: false,
    dataModalOffers: {},
  },

  init: function () {
    bcl.c.distributor.results.props.$container = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.results.props.className)[0];

    bcl.c.distributor.results.props.$containerSurprise = bcl.c.distributor.props.$container.querySelectorAll("." + bcl.c.distributor.results.props.classNameContainerSurprise)[0];

    bcl.c.distributor.results.props.showInPagination = bcl.c.distributor.results.props.numberPagination;

    if (!bcl.c.distributor.props.urlAsync || bcl.c.distributor.props.loadDataAsync) {
      if (!bcl.c.distributor.isComparator) {
        bcl.c.distributor.results.props.$pagination = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.paginationClassName)[0];

        bcl.u.addClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);
      }
      bcl.c.distributor.results.props.hidePagination = true;

      if (!bcl.c.distributor.props.loaded) {
        bcl.c.distributor.loadAsyncData(null, bcl.c.distributor.results.initPagination);
      }
    } else {
      bcl.c.distributor.results.initPagination();
      bcl.c.distributor.initCards(false, true);
    }
  },

  initPagination: function () {
    if (!bcl.c.distributor.isComparator) {
      bcl.c.distributor.results.props.$pagination = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.paginationClassName)[0];
      bcl.c.distributor.results.props.numberPagination = parseInt(bcl.c.distributor.results.props.$pagination.dataset.numbershow);

      bcl.c.distributor.results.props.$pagination.addEventListener("click", function () {
        bcl.c.distributor.requestAsyncData(bcl.c.distributor.results.clickPagination);
      });
    } else {
      const $paginationBtn = document.querySelector(".show-more-JS");
      bcl.c.distributor.results.props.numberPagination = parseInt($paginationBtn.dataset.numbershow);

      $paginationBtn.addEventListener("click", function () {
        bcl.c.distributor.requestAsyncData(bcl.c.distributor.results.clickPagination);
      });
    }
  },

  clickPagination: function () {
    let $cards = bcl.c.distributor.results.props.$container.querySelectorAll("." + bcl.c.distributor.results.props.hidePagClassName + ":not(." + bcl.c.distributor.props.classNameHidden + ")"),
      length = bcl.c.distributor.results.props.numberPagination;

    bcl.c.distributor.results.props.showInPagination += bcl.c.distributor.results.props.numberPagination;

    if ($cards.length <= bcl.c.distributor.results.props.numberPagination) {
      bcl.u.addClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);
      length = $cards.length;
      bcl.c.distributor.results.props.numberPagination += $cards.length;
    } else {
      bcl.c.distributor.results.props.numberPagination = bcl.c.distributor.results.props.numberPaginationCONST;
    }

    while (length != 0) {
      if (!$cards[length - 1].classList.contains("hidden")) {
        bcl.u.removeClass($cards[length - 1], bcl.c.distributor.results.props.hidePagClassName);
        length--;
      }
    }

    bcl.c.flipcard.init();

    bcl.u.triggerEvent(window, "cmp-image-global-redraw");

    setTimeout(function () {
      $cards = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.classNameContainerCard);
      bcl.c.distributor.setPositionCard($cards);
    }, 2000);
  },

  afterInitCards: function ($cards) {
    if (bcl.c.distributor.results.props.hidePagination) {
      return;
    }

    if ($cards.length < bcl.c.distributor.results.props.numberPagination) {
      bcl.u.addClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);
    } else {
      bcl.u.removeClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);
    }
  },

  load: function (data) {
    bcl.c.distributor.results.hideAll();
    bcl.c.distributor.results.showByData(data);
    bcl.c.distributor.updateShowMore();
  },

  hideAll: function () {
    const $results = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.classNameContainerCard);

    bcl.u.addClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);

    bcl.u.forEach($results, function ($result) {
      bcl.u.addClass($result, bcl.c.distributor.props.classNameHidden);
      bcl.u.removeClass($result, bcl.c.distributor.results.props.hidePagClassName);
    });

    //get card surprise banner from 3 position to hide it
    const $cardSurpriseBanner = bcl.c.distributor.results.props.$container.getElementsByClassName(bcl.c.distributor.results.props.hotelSurprise3Position);

    //Hide card surprise banner
    bcl.u.forEach($cardSurpriseBanner, function ($result) {
      bcl.u.addClass($result, bcl.c.distributor.props.classNameHidden);
      bcl.u.removeClass($result, bcl.c.distributor.results.props.hidePagClassName);
    });

    bcl.c.distributor.map.hideAll();
  },

  showByData: function (originalData) {
    const data = structuredClone(originalData);
    const cardSelectors = [];
    data.forEach(function (d) {
      d.propInData = bcl.u.getInData(d, bcl.c.distributor.props.propInData);
      cardSelectors.push("[" + bcl.c.distributor.results.props.nameAttrCard + '="' + d.propInData + '"]');
    });
    if (cardSelectors?.length > 0) {
      bcl.c.distributor.results.props.$container.querySelectorAll(cardSelectors.join(",")).forEach(function ($card, i) {
        let item;
        let j = 0;
        let d = data[j];
        while (!item && d) {
          if (d.propInData === $card.getAttribute(bcl.c.distributor.results.props.nameAttrCard)) {
            item = d;
            data.splice(data.indexOf(d), 1);
          }
          j++;
          d = data[j];
        }
        if (!item) {
          item = data[j];
        }

        bcl.c.distributor.results.update(item, i, $card) && bcl.c.distributor.map.showByItem(item);
      });
    }

    if (originalData.length > bcl.c.distributor.results.props.numberPagination) {
      bcl.u.removeClass(bcl.c.distributor.results.props.$pagination, bcl.c.distributor.props.classNameHidden);
    }

    bcl.c.distributor.map.showByData();
  },

  update: function (item, index, $card) {
    $card = $card || bcl.c.distributor.results.props.$container.querySelector("[" + bcl.c.distributor.results.props.nameAttrCard + '="' + bcl.u.getInData(item, bcl.c.distributor.props.propInData) + '"]');
    if (!$card) {
      return;
    }

    const flipCard = $card.querySelector(bcl.c.flipcard.props.selectorFlipCard);
    flipCard && bcl.c.flipcard.init(flipCard);

    if (index >= bcl.c.distributor.results.props.numberPagination) {
      bcl.u.addClass($card, bcl.c.distributor.results.props.hidePagClassName);
    } else {
      bcl.c.distributor.loadLazyCard($card);
    }

    if ($card.dataset.id == bcl.c.distributor.props.hotelRelatedId) {
      bcl.u.removeClass($card, bcl.c.distributor.results.props.hidePagClassName);
    }

    bcl.u.removeClass($card, bcl.c.distributor.props.classNameHidden);
    bcl.u.triggerEvent(window, "resize");
    return $card;
  },

  moveBannerToPosition: function () {
    const $hotelCards = document.querySelectorAll(".result__list-item:not(.lazy-JS):not(.mod--hidePagination)");
    if ($hotelCards.length > 2 && document.getElementsByClassName("hotelSurpriseBanner-JS").length > 0) {
      const $banner = document.getElementsByClassName("hotelSurpriseBanner-JS")[0].parentNode;
      $hotelCards[1].after($banner);
    }
  },

  updateOrder: function (data) {
    for (let i = data.length - 1; i >= 0; i--) {
      let $card = bcl.c.distributor.results.searchCard(data[i], bcl.c.distributor.props.propInData);
      const firstChild = bcl.c.distributor.results.props.$container.children[0];
      if (bcl.c.distributor.results.props.$container?.closest("." + bcl.c.distributor.results.props.offerCollectionsSelector)) {
        $card = bcl.c.distributor.results.searchCard(data[i], "offer.path");
        if ($card) {
          $card = $card.closest(".result__list-item");
        }
      }
      if (!$card) {
        $card = bcl.c.distributor.results.searchCard(data[i], "path");
      }
      if ($card) {
        bcl.u.addClass($card, bcl.c.distributor.results.props.hidePagClassName);
        if (firstChild) {
          bcl.c.distributor.results.props.$container.insertBefore($card, firstChild);
        } else {
          bcl.c.distributor.results.props.$container.appendChild($card);
        }
      }
    }

    for (let i = 0; i < bcl.c.distributor.props.data.length; i++) {
      const hotelId = bcl.c.distributor.props.data[i].info.hotelId;
      const $card = bcl.c.distributor.props.$container.querySelector('article[data-hotel-id="' + hotelId + '"]');
      if ($card) {
        $card.setAttribute("data-index", i);
      }
    }
    bcl.c.distributor.results.clickPagination();
  },

  searchCard: function (data, propInData) {
    return bcl.c.distributor.results.props.$container.querySelector("[" + bcl.c.distributor.results.props.nameAttrCard + '="' + bcl.u.getInData(data, propInData) + '"]');
  },

  hidePriceWhenIsZeroCardSurprise: function ($price) {
    const $parentPrice = $price.closest(".span-card-surprise-JS");
    bcl.u.forEach($parentPrice.children, function (child) {
      if ($price.textContent === "0") {
        bcl.u.addClass(child, "hidden");
      } else {
        bcl.u.removeClass(child, "hidden");
      }
    });
  },

  hidePriceWhenIsZero: function ($price, data) {
    const $parentPrice = $price.closest("." + bcl.c.distributor.props.classCardHotelPrice);
    bcl.u.forEach($parentPrice.children, function (child) {
      if ($price.textContent === "0" || data.info.hotelLocalPrice === 0) {
        bcl.u.addClass(child, "hidden");
      } else {
        bcl.u.removeClass(child, "hidden");
      }
    });
  },

  initCard: function ($card, $article, data, index) {
    if (index >= bcl.c.distributor.results.props.numberPagination) {
      bcl.u.addClass($card, bcl.c.distributor.results.props.hidePagClassName);
      bcl.u.addClass($card, bcl.c.distributor.results.props.lazyCard);
    }

    const isOffer = bcl.c.distributor.results.initOfferCard($card, $article, data);

    if (isOffer) {
      return;
    }

    bcl.s.tooltip.init($card);

    $card.setAttribute("data-id", data.info.hotelId);

    if (bcl.u.containsClass($card, bcl.c.distributor.results.props.lazyCard)) {
      return;
    }
    const $prices = $card.querySelectorAll(bcl.c.distributor.props.selectorPrice);
    if ($prices) {
      bcl.u.forEach($prices, function ($price) {
        const price = bcl.c.distributor.results.getPrice($price, data),
          formattedPrice = bcl.s.currency.formatPrice(price);
        $price.dataset.marketPrice = price;
        $price.textContent = formattedPrice;
        if (bcl.u.containsClass($card, "bannerCardSurprise-JS") || bcl.u.containsClass($card, bcl.c.distributor.results.props.classNameContainerCampaignCard)) {
          bcl.c.distributor.results.hidePriceWhenIsZeroCardSurprise($price, data);
        } else {
          bcl.c.distributor.results.hidePriceWhenIsZero($price, data);
        }
      });

      if (bcl.s.currency.props.currency.market !== localStorage.getItem(bcl.s.currency.props.attributes.localStorageCurrency)) {
        bcl.s.currency.convertPrices($card);
      }
    }

    bcl.c.distributor.results.checkNotOpenedRelatedHotel($card, $article);
  },
  checkNotOpenedRelatedHotel($card, $article) {
    if ($article.dataset.hotelId === bcl.c.distributor.props.hotelRelatedId) {
      if (bcl.c.distributor.props.openingDate && new Date() < bcl.c.distributor.props.openingDate) {
        const $bodyList = $card.querySelector(".c-card-hotel__content-body-list");
        bcl.c.distributor.results.hiddenContentCard(null, $bodyList);
        bcl.c.distributor.results.createContentCard($bodyList, bcl.c.distributor.props.messageNoAvailableUntil);
      }
    }
  },
  getPrice: function ($price, data) {
    const mybPriceClass = bcl.c.hotelRoomList ? bcl.c.hotelRoomList.props.mybPriceClass : "c-mybprice__value-JS";
    let result = data.info.hotelLocalPrice;

    if (bcl.u.containsClass($price, mybPriceClass)) {
      result = data.info.hotelLocalMybPrice;
    }

    return result;
  },
  chooseContentNotAvailable: function ($card) {
    const $bodyList = $card.querySelector(".c-card-hotel__content-body-list"),
      $imageCard = $card.querySelector(".c-container-image");

    if (!$card.classList.contains("bannerCardSurprise-JS")) {
      bcl.u.addClass($imageCard, "mod--img-disabled");
    }

    bcl.c.distributor.results.hiddenContentCard($card, $bodyList);
    bcl.c.distributor.results.createContentCard($bodyList);

    if (bcl.c.distributor.props.comparatorRelateds && bcl.c.distributor.props.hotelRelatedId != $card.dataset.id) {
      bcl.u.addClass($card, bcl.c.distributor.props.classNameHidden);
    }

    bcl.c.distributor.results.showAlternativeMessage($card);

    const $title = $card.querySelector(bcl.c.distributor.props.selectorTitle);

    $title &&
      $title.addEventListener("click", function () {
        if (bcl.c.fastbooking.calendar.props.$deleteDates) {
          bcl.u.triggerEvent(bcl.c.fastbooking.calendar.props.$deleteDates, "click");
        }
      });
  },

  showAlternativeMessage: function ($card) {
    if (bcl.c.distributor.props.comparatorRelateds && bcl.c.distributor.props.hotelRelatedId === $card.dataset.id) {
      const $text = bcl.c.distributor.props.$container.querySelector(".related-message-JS");
      if ($text) {
        bcl.u.removeClass($text, "hidden");
        $card.appendChild($text);
        const data = bcl.u.getDates(bcl.c.fastbooking.calendar.readCookies());
        data && bcl.u.showDatesMessage(data, $text, "date");
      }
    }
  },

  hiddenContentCard: function ($card, $bodyList) {
    if ($card) {
      const footer = $card.querySelector(".c-card-hotel__content-footer");
      bcl.u.addClass(footer, "hidden");
      // hide bar compare when hotel not available
      const barComparator = $card.querySelector(".result__comparator-bar");
      bcl.u.addClass(barComparator, "hidden");
    }

    if ($bodyList) {
      const ul = $bodyList.querySelector(".mod--checks");
      bcl.u.addClass(ul, "hidden");
    }
  },

  createContentCard: function ($bodyList, message) {
    if ($bodyList) {
      const divChild = document.createElement("div");
      divChild.innerHTML = '<div class="cmp-icon mod--size-24 dynamic-warning_switched"></div>' + (message ? message : bcl.c.distributor.props.messageNoAvailable);
      bcl.u.addClass(divChild, "c-card-hotel__content-not-available");
      const parent = $bodyList.closest(".c-card-hotel__content");
      if (!parent.querySelector(".c-card-hotel__content-not-available")) {
        parent.querySelector("footer") ? parent.querySelector("footer").before(divChild) : parent.appendChild(divChild);
      }
    }
  },

  loadDataFilterDateInCard: function (item, id) {
    const $card = bcl.c.distributor.results.props.$container.querySelector('[data-id="' + id + '"]');

    if (!$card) {
      return;
    }

    const distributor = $card.querySelector("." + bcl.c.distributor.results.props.classNameCard),
      myb = distributor?.classList?.contains(bcl.c.distributor.results.props.classNameMybDistributor),
      priceFromMapMyb = item?.myBarceloHotelPriceFrom && item?.myBarceloHotelPriceFrom[id] ? item.myBarceloHotelPriceFrom[id] : null,
      priceFromMapPublic = item?.hotelPriceFromMap && item?.hotelPriceFromMap[id] ? item.hotelPriceFromMap[id] : null;

    const priceFromMap = myb && priceFromMapMyb ? priceFromMapMyb : priceFromMapPublic;
    const $price = $card.querySelector("." + bcl.c.distributor.order.props.classNamePrice);
    if (bcl.u.containsClass($card.children[1], bcl.c.distributor.results.props.lazyCard)) {
      $card.children[1].dataset.price = priceFromMap.Amount;
      $card.children[1].dataset.priceTaxes = priceFromMap.AmountWithTaxesFees;
      bcl.u.removeClass($card.children[1], bcl.c.distributor.results.props.lazyCard);
    } else {
      // Error API for hotel
      if (item.errorApi) {
        bcl.u.removeClass($card, bcl.c.distributor.props.classNameHidden);
        return;
      }

      // No dispo
      if (!priceFromMap) {
        bcl.c.distributor.results.chooseContentNotAvailable($card);

        const $price = $card.querySelector("." + bcl.c.distributor.order.props.classNamePrice);
        if (bcl.c.distributor.results.setPrice($price, priceFromMap)) {
          bcl.s.currency.convertPrices($price);
        }
      }

      // Price to a 0 no to show
      if ((!priceFromMap || priceFromMap.Amount == 0) && (!priceFromMap || priceFromMap.AmountWithTaxesFees == 0)) {
        bcl.c.distributor.results.chooseContentNotAvailable($card);

        const $price = $card.querySelector("." + bcl.c.distributor.order.props.classNamePrice);
        if (bcl.c.distributor.results.setPrice($price, priceFromMap)) {
          bcl.s.currency.convertPrices($price);
        }
      }

      if (!priceFromMap || parseInt(priceFromMap.minStay) > 0) {
        bcl.c.distributor.results.chooseContentNotAvailable($card);
      }

      //Array has length 0, cast to object for loop it
      const markersPopups = Object.assign({}, bcl.c.distributor.map.props.markersPopups);

      Object.keys(markersPopups).forEach(function (key) {
        //Object.keys[0] return hotel Id
        const mapCard = markersPopups[key].containerDiv.querySelector('[data-hotel-id="' + Object.keys(item.hotelPriceFromMap)[0] + '"]');
        if (mapCard != null) {
          const $price = mapCard.querySelector("." + bcl.c.distributor.order.props.classNamePrice);
          if (bcl.c.distributor.results.setPrice($price, priceFromMap)) {
            bcl.s.currency.convertPrices($price);
          }
        }
      });

      if (bcl.c.distributor.map.props.marketsLoaded) {
        bcl.u.forEach(bcl.c.distributor.comparator.props.markers, function (marker) {
          bcl.c.distributor.map.updateLabelMarker("currency", marker);
        });
      }
      if (bcl.c.distributor.results.setPrice($price, priceFromMap)) {
        bcl.s.currency.convertPrices($price);
        bcl.u.removeClass($card.querySelector(".c-mybprice__value-JS"), bcl.c.distributor.props.selectorBlocked);
      }
    }

    bcl.c.distributor.priceFetchUtag($card);
  },

  setPrice: function ($el, data) {
    const $price = $el.querySelector(".c-price__value-JS");
    let value = data.Amount || data.AmountWithTaxesFees;

    value = Math.ceil(value);

    $price.dataset.marketPrice = value;
    $price.innerText = value;

    if (value !== 0) {
      bcl.u.removeClass($price, bcl.c.distributor.props.selectorBlocked);
      bcl.u.removeClass($price.parentElement.parentElement, bcl.c.distributor.props.classNameHidden);
      bcl.u.removeClass($price.parentElement.parentElement.previousElementSibling, bcl.c.distributor.props.classNameHidden);
    } else {
      const $surprisePrice = $price.closest(".span-card-surprise-JS");
      if ($surprisePrice) {
        bcl.u.addClass($surprisePrice, bcl.c.distributor.props.classNameHidden);
        const $title = $surprisePrice.parentElement.querySelector(".c-card__titles-head");
        bcl.u.addClass($title, bcl.c.distributor.props.classNameHidden);
      } else {
        bcl.u.addClass($price.parentElement.parentElement, bcl.c.distributor.props.classNameHidden);
        bcl.u.addClass($price.parentElement.parentElement.previousElementSibling, bcl.c.distributor.props.classNameHidden);
      }
    }

    return true;
  },

  minifyPath: function (path) {
    return path.replace("/content/barcelo/", "/_barcelo/").replace("/content/dam/bhg/data/", "/_data/");
  },

  loadAsyncData: function (html) {
    bcl.c.distributor.results.props.$container.innerHTML = html;
    bcl.c.distributor.results.initPagination();
  },
};
