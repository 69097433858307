bcl.c.distributor.facets = {
  props: {
    classNameContainer: "c-hotels-comparator__sidebar",
    className: "sidebar__facets-list",
    classNameDimension: "dimension",
    classNameFacet: "facet",
    classNameCount: "count",
    classNameFacetsActive: "sidebar__applicated-filters",
    classNameFacetsItemsActive: "applicated-filters__container",
    classNameClearAllActive: "applicated-filters__empty-btn",
    classNameMoreOrLess: "moreOrLess",
    classNameMoreOrLessActive: "moreOrLessActive",
    classNameContainerMoreOrLess: "facets-list__toggle-btn",
    classNameOpenMobile: "open",
    nameAttrDataDimension: "data-dimension",
    nameAttrDataFacet: "data-facet",
    nameAttrDataDimensionInit: "data-show-dimension",
    offerCollectionsComparatorSelector: "JS-offers-collections",
    data: {},
    $container: null,
    facetActiveShow: null,
    facetsQueryParam: "",
    dimensionShow: null,
    facetShow: null,
    $containerFacetsActive: null,
    $containerFacetsItemsActive: null,
    $containerSidebar: null,
    activeHotelFilterClass: "activeHotelsfilter",
    facetRemove: [],
    showMoreClickeds: [],
    classNameComparator: "c-hotels-comparator",
  },

  init: function () {
    bcl.c.distributor.facets.props.$containerSidebar = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.facets.props.classNameContainer)[0];

    if (bcl.c.distributor.facets.props.$containerSidebar) {
      bcl.c.distributor.facets.props.$container = bcl.c.distributor.facets.props.$containerSidebar.getElementsByClassName(bcl.c.distributor.facets.props.className)[0];
    }

    if (bcl.c.distributor.facets.props.$containerSidebar) {
      bcl.c.distributor.facets.props.$containerFacetsActive = bcl.c.distributor.facets.props.$containerSidebar.getElementsByClassName(bcl.c.distributor.facets.props.classNameFacetsActive)[0];
    }

    if (bcl.c.distributor.facets.props.$containerFacetsActive) {
      bcl.c.distributor.facets.props.$containerFacetsItemsActive = bcl.c.distributor.facets.props.$containerFacetsActive.getElementsByClassName(bcl.c.distributor.facets.props.classNameFacetsItemsActive)[0];
    }

    if (bcl.c.distributor.props.$container) {
      bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile = bcl.c.distributor.props.$container.querySelector("." + bcl.c.distributor.facets.props.classNameFacetsItemsActive + ".mobileOnly");
    }

    if (!bcl.c.distributor.facets.props.$container) {
      return;
    }

    bcl.c.distributor.facets.initAttr();
    bcl.c.distributor.facets.initNodes();
    bcl.c.distributor.facets.initClearAllActive();

    bcl.c.distributor.facets.blockFacets();

    document.addEventListener(bcl.map.props.nameInitEvent, function () {
      bcl.c.distributor.map.init();
      bcl.c.distributor.facets.selectedCheckedFacet();
    });
  },

  initAttr: function () {
    const $node = bcl.c.distributor.facets.props.$container;
    let defaultFacetShow = 6;
    if ($node?.closest("." + bcl.c.distributor.facets.props.offerCollectionsComparatorSelector)) {
      defaultFacetShow = Infinity;
    }
    bcl.c.distributor.facets.props.facetShow = defaultFacetShow;
    bcl.c.distributor.facets.props.dimensionShow = $node.getAttribute(bcl.c.distributor.facets.props.nameAttrDataDimensionInit) || 3;
    bcl.c.distributor.facets.props.facetActiveShow = 5;
  },

  initNodes: function () {
    const $dimensions = bcl.c.distributor.facets.props.$container.getElementsByClassName(bcl.c.distributor.facets.props.classNameDimension);

    if (!$dimensions) {
      return;
    }

    bcl.u.forEach($dimensions, bcl.c.distributor.facets.processDimension);

    if (bcl.c.distributor.facets.props.facetsQueryParam && bcl.u.utag.isEnabled()) {
      bcl.c.distributor.facets.pushEvent("filters|" + utag_data.pagecategorization, "fast booking filter", bcl.c.distributor.facets.props.facetsQueryParam, null, "fast booking filter");
    }
  },

  initClearAllActive: function () {
    const $clearAll = bcl.c.distributor.facets.props.$containerFacetsActive.getElementsByClassName(bcl.c.distributor.facets.props.classNameClearAllActive)[0];

    $clearAll.addEventListener("click", bcl.c.distributor.facets.clickInClearAll);

    bcl.c.distributor.facets.processMoreOrLess(bcl.c.distributor.facets.props.$containerFacetsActive);
  },

  clickInClearAll: function () {
    if (Object.keys(bcl.c.distributor.facets.props.data).length === 0) {
      return;
    }
    bcl.c.distributor.props.blockedRequest = true;

    for (const key in bcl.c.distributor.facets.props.data) {
      const obj = bcl.c.distributor.facets.props.data[key];
      for (const item in obj) {
        if (!obj[item].disabled) {
          obj[item].checked = false;
          bcl.c.distributor.facets.clickInFacet(obj[item], key, item);
        }
      }
    }

    bcl.c.distributor.props.blockedRequest = false;

    bcl.c.distributor.results.props.numberPagination = bcl.c.distributor.results.props.numberPaginationCONST;

    bcl.c.distributor.request("reset");
  },

  processDimension: function ($dimension, i) {
    const dimension = $dimension.getAttribute(bcl.c.distributor.facets.props.nameAttrDataDimension),
      $facets = $dimension.getElementsByClassName(bcl.c.distributor.facets.props.classNameFacet);

    bcl.u.forEach($facets, function ($element) {
      bcl.c.distributor.facets.processFacet($element, dimension, i);
    });

    for (let n = 0; n < bcl.c.distributor.facets.props.facetRemove.length; n++) {
      const $item = bcl.c.distributor.facets.props.facetRemove[n];

      $item.parentNode.removeChild($item);
    }

    bcl.c.distributor.facets.props.facetRemove = [];

    bcl.c.distributor.facets.processMoreOrLess($dimension, $facets, bcl.c.distributor.facets.props.facetShow);
    bcl.c.distributor.facets.clickMoreOrLess($dimension);
  },

  processMoreOrLess: function ($dimension, $facets, count) {
    let $node = $dimension.getElementsByClassName(bcl.c.distributor.facets.props.classNameContainerMoreOrLess);

    if (!$node || !$node[0]) {
      return;
    }

    $node = $node[0];

    $node.addEventListener("click", function () {
      bcl.c.distributor.requestAsyncData();

      bcl.u.toggleClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLessActive);
      bcl.c.distributor.facets.clickMoreOrLess($dimension);

      bcl.c.distributor.facets.checkClickeds($dimension);
    });

    $node.addEventListener("click-JS", function () {
      bcl.c.distributor.requestAsyncData();

      bcl.u.toggleClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLessActive);
      bcl.c.distributor.facets.clickMoreOrLess($dimension);
    });

    if ($facets && $facets.length > count) {
      bcl.u.toggleClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLess);
    }
  },

  clickMoreOrLess: function ($dimension) {
    const active = bcl.u.containsClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLessActive),
      method = !active ? "addClass" : "removeClass";

    const $facets = $dimension.getElementsByClassName("facetShow");

    bcl.u.forEach($facets, function ($element, index) {
      if (index > bcl.c.distributor.facets.props.facetShow - 1 && !bcl.u.containsClass($element, "facetPrice")) {
        bcl.u[method]($element, "hidden");
      }
    });
  },

  checkFacet: function ($element, $checkbox, dimension) {
    if ("hotel_destinations" !== dimension) {
      return !!$checkbox;
    }

    if (!$checkbox || $checkbox.disabled || $element.children[0].innerText.match("/content/dam/bhg")) {
      if (!bcl.editMode) {
        bcl.c.distributor.facets.props.facetRemove.push($element);
      } else {
        bcl.u.addClass($element, "bg-b-red");
      }

      return;
    }

    bcl.c.distributor.facets.checkParentsFacet($element, $checkbox);

    return true;
  },

  checkParentsFacet: function ($element) {
    let parents = $element.dataset.tree;

    if (!parents) {
      bcl.c.distributor.facets.changeClassLevel($element);

      return;
    }

    parents = parents.split("/");

    if ($element.dataset.parent && parents.length === 1) {
      bcl.c.distributor.facets.changeClassLevel($element);
    }
  },

  changeClassLevel: function ($element) {
    if (bcl.editMode) {
      bcl.u.addClass($element, "bg-b-red");

      return;
    }

    bcl.u.addClass($element, "facet-tab-0");
    bcl.u.removeClass($element, "facet-tab-" + $element.dataset.level);
  },

  processFacet: function ($element, dimension) {
    const $checkbox = $element.querySelector("input");

    if (!bcl.c.distributor.facets.checkFacet($element, $checkbox, dimension)) {
      return;
    }

    const facet = $element.getAttribute(bcl.c.distributor.facets.props.nameAttrDataFacet);

    if (bcl.c.distributor.props.queryParams[dimension] && bcl.c.distributor.props.queryParams[dimension][facet]) {
      bcl.c.distributor.facets.props.facetsQueryParam += (bcl.c.distributor.facets.props.facetsQueryParam ? "|" : "") + facet;
      bcl.c.distributor.facets.addProcessFacet(dimension, facet, $checkbox);
    }
    if (!$element?.closest("." + bcl.c.distributor.facets.props.offerCollectionsComparatorSelector)) {
      if (bcl.c.distributor.props.selectors[facet]) {
        bcl.c.distributor.facets.addProcessFacetWithRedirect(dimension, facet, $checkbox);
      } else {
        $checkbox.addEventListener("change", function () {
          bcl.c.distributor.facets.clickInFacet($checkbox, dimension, facet);
        });
      }
    }
  },

  addProcessFacetWithRedirect: function (dimension, facet, $checkbox) {
    $checkbox.setAttribute("checked", true);
    bcl.c.distributor.facets.addData(dimension, facet, $checkbox);

    $checkbox.addEventListener("change", function () {
      bcl.c.distributor.facets.clickInFacetWithRedirect($checkbox, dimension, facet);
    });
  },

  addProcessFacet: function (dimension, facet, $checkbox) {
    $checkbox.setAttribute("checked", true);
    bcl.c.distributor.facets.addData(dimension, facet, $checkbox);
    bcl.c.distributor.facets.addActive(dimension, facet, $checkbox);
  },

  clickInFacetWithRedirect: function ($checkbox, dimension, facet) {
    window.location.href = window.location.href.replace("--" + facet, "");
  },

  clickInFacet: function ($checkbox, dimension, facet) {
    let successData = false;

    if ($checkbox.checked && !$checkbox.disabled) {
      if (bcl.u.utag.isEnabled()) {
        bcl.c.distributor.facets.pushEvent("filters|" + utag_data.pagecategorization, dimension, facet, null, dimension);
      }

      successData = bcl.c.distributor.facets.addData(dimension, facet, $checkbox);
      successData && bcl.c.distributor.facets.addActive(dimension, facet, $checkbox);
    } else {
      successData = bcl.c.distributor.facets.removeData(dimension, facet);
      successData && bcl.c.distributor.facets.removeActive(dimension, facet);
    }

    if (successData) {
      if (!bcl.c.distributor.map.props.loaded) {
        document.addEventListener(bcl.map.props.nameInitEvent, function () {
          bcl.c.distributor.map.init();
          bcl.c.distributor.request();
        });
        bcl.map.init();
      } else {
        bcl.c.distributor.request();
      }
    }
    bcl.u.removeBhgLoading(bcl.c.distributor.props.$container);
  },

  addData: function (dimension, facet, $checkbox) {
    if (!bcl.c.distributor.facets.props.data) {
      return false;
    }

    if (!bcl.c.distributor.facets.props.data[dimension]) {
      const obj = {};

      obj[facet] = $checkbox;
      bcl.c.distributor.facets.props.data[dimension] = obj;

      return true;
    }

    if (bcl.c.distributor.facets.props.data[dimension]) {
      if ("hotel_destinations" === dimension && !bcl.c.distributor.facets.props?.$container.closest("." + bcl.c.distributor.facets.props.offerCollectionsComparatorSelector)) {
        for (const key in bcl.c.distributor.facets.props.data[dimension]) {
          if (!bcl.c.distributor.facets.props.data[dimension][key].disabled) {
            bcl.c.distributor.facets.props.data[dimension][key].checked = false;
          }
          bcl.c.distributor.facets.removeActive(dimension, key);
          delete bcl.c.distributor.facets.props.data[dimension][key];
        }
      }

      bcl.c.distributor.facets.props.data[dimension][facet] = $checkbox;
      return true;
    }

    return false;
  },

  removeData: function (dimension, facet) {
    if (!bcl.c.distributor.facets.props.data || !bcl.c.distributor.facets.props.data[dimension] || !bcl.c.distributor.facets.props.data[dimension][facet]) {
      return false;
    }

    delete bcl.c.distributor.facets.props.data[dimension][facet];

    if (Object.keys(bcl.c.distributor.facets.props.data[dimension]).length === 0) {
      delete bcl.c.distributor.facets.props.data[dimension];
    }

    return true;
  },

  addActive: function (dimension, facet, $checkbox) {
    if (!bcl.c.distributor.facets.props.$containerFacetsItemsActive) {
      return;
    }
    bcl.c.distributor.facets.props.$containerFacetsItemsActive.appendChild(bcl.c.distributor.facets.createNodeActive(dimension, facet, $checkbox));
    if (bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile) {
      bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile.appendChild(bcl.c.distributor.facets.createNodeActive(dimension, facet, $checkbox));
    }

    const childrenLength = bcl.c.distributor.facets.props.$containerFacetsItemsActive.childElementCount;

    if (childrenLength > 0) {
      bcl.u.removeClass(bcl.c.distributor.facets.props.$containerFacetsActive, bcl.c.distributor.props.classNameHidden);

      if (childrenLength > bcl.c.distributor.facets.props.facetActiveShow) {
        bcl.u.addClass(bcl.c.distributor.facets.props.$containerFacetsActive, bcl.c.distributor.facets.props.classNameMoreOrLess);
      }
    }
  },

  pushEvent: function (category, action, label, value, event) {
    try {
      const obj = {
        event_category: category,
        event_action: action && action.replace("_", " "),
        event_label: label && label.replace("_", " "),
        event: event && event.replace("_", " "),
      };

      if (value) {
        obj.event_value = value;
      }

      bcl.u.utag.push(obj);
    } catch (e) {
      console.error("Event push error", e);
    }
  },

  createNodeActive: function (dimension, facet, $checkbox) {
    const $element = document.createElement("div"),
      $span = document.createElement("span"),
      $close = document.createElement("div");

    $element.setAttribute(bcl.c.distributor.facets.props.nameAttrDataDimension, dimension);
    $element.setAttribute(bcl.c.distributor.facets.props.nameAttrDataFacet, facet);

    bcl.u.addClass($element, "applicated-filters__filtered-item");
    bcl.u.addClass($close, "applicated-filters__delete-filtered");

    $span.innerText = $checkbox.getAttribute("name");

    $element.appendChild($span);
    $element.appendChild($close);

    $close.addEventListener("click", function () {
      $checkbox.checked = false;
      bcl.c.distributor.facets.clickInFacet($checkbox, dimension, facet);
    });

    return $element;
  },

  removeActive: function (dimension, facet) {
    if (!bcl.c.distributor.facets.props.$containerFacetsItemsActive || bcl.c.distributor.facets.props.$containerFacetsItemsActive.childElementCount === 0) {
      return;
    }

    const valueSearch = "[" + bcl.c.distributor.facets.props.nameAttrDataDimension + '="' + dimension + '"]' + "[" + bcl.c.distributor.facets.props.nameAttrDataFacet + '="' + facet + '"]';
    let $facet = bcl.c.distributor.facets.props.$containerFacetsItemsActive.querySelector(valueSearch);

    if ($facet) {
      bcl.c.distributor.facets.props.$containerFacetsItemsActive.removeChild($facet);
    }
    if (bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile) {
      $facet = bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile.querySelector(valueSearch);

      if ($facet) {
        bcl.c.distributor.facets.props.$containerFacetsItemsActiveMobile.removeChild($facet);
      }
    }

    const childrenLength = bcl.c.distributor.facets.props.$containerFacetsItemsActive.childElementCount;

    if (childrenLength === 0) {
      bcl.u.addClass(bcl.c.distributor.facets.props.$containerFacetsActive, bcl.c.distributor.props.classNameHidden);
    }

    if (childrenLength <= bcl.c.distributor.facets.props.facetActiveShow) {
      bcl.u.removeClass(bcl.c.distributor.facets.props.$containerFacetsActive, bcl.c.distributor.facets.props.classNameMoreOrLess);
      bcl.u.removeClass(bcl.c.distributor.facets.props.$containerFacetsActive, bcl.c.distributor.facets.props.classNameMoreOrLessActive);
    }
  },

  load: function (data) {
    bcl.c.distributor.facets.props.lastData = data.facets;

    bcl.c.distributor.facets.hideAll();
    bcl.c.distributor.facets.showByData(data);

    delete bcl.c.distributor.facets.props.lastData;
  },

  hideAll: function () {
    const $dimensions = bcl.c.distributor.facets.props.$container.getElementsByClassName(bcl.c.distributor.facets.props.classNameDimension);
    if (!bcl.c.distributor.facets.props.$container.closest("." + bcl.c.distributor.facets.props.offerCollectionsComparatorSelector)) {
      bcl.u.forEach($dimensions, bcl.c.distributor.facets.hideDimension);
    }
  },

  hideDimension: function ($dimension) {
    bcl.u.addClass($dimension, bcl.c.distributor.props.classNameHidden);

    const $facets = $dimension.querySelectorAll("[data-facet]");
    bcl.u.forEach($facets, function ($facet) {
      bcl.u.addClass($facet, bcl.c.distributor.props.classNameHidden);
      bcl.u.removeClass($facet, "facetShow");
    });
  },

  showByData: function (data) {
    for (let i = 0; i < data.facets.length; i++) {
      bcl.c.distributor.facets.updateDimension(data.facets[i], bcl.c.distributor.facets.updateFacet);
    }

    const obj = {
      field: "price",
      items: data.priceFacets,
    };

    bcl.c.distributor.facets.updateDimension(obj, bcl.c.distributor.facets.updateFacetPrices);
  },

  updateDimension: function (dimension, callback) {
    const $dimension = bcl.c.distributor.facets.props.$container.querySelector("[" + bcl.c.distributor.facets.props.nameAttrDataDimension + '="' + dimension.field + '"]');

    if (!$dimension || !dimension.items) {
      return;
    }

    bcl.u.removeClass($dimension, bcl.c.distributor.props.classNameHidden);

    for (let i = 0; i < dimension.items.length; i++) {
      callback(dimension.items[i], $dimension);
    }

    bcl.u.removeClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLessActive);

    bcl.c.distributor.facets.clickMoreOrLess($dimension);

    if (dimension.items.length > bcl.c.distributor.facets.props.facetShow) {
      bcl.u.addClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLess);
    } else {
      bcl.u.removeClass($dimension, bcl.c.distributor.facets.props.classNameMoreOrLess);
    }

    for (let i = 0; i < bcl.c.distributor.facets.props.showMoreClickeds.length; i++) {
      if ($dimension.dataset.dimension == bcl.c.distributor.facets.props.showMoreClickeds[i]) {
        const $node = $dimension.querySelector("." + bcl.c.distributor.facets.props.classNameContainerMoreOrLess);
        const clickFromJs = new CustomEvent("click-JS");
        $node.dispatchEvent(clickFromJs);
      }
    }
  },

  updateFacetPrices: function (facet, $dimension) {
    const selector = facet.currency + "-" + (facet.lower_limit || "") + "-" + (facet.upper_limit || ""),
      $facet = $dimension.querySelector("[" + bcl.c.distributor.facets.props.nameAttrDataFacet + '="' + selector + '"]');

    if (!$facet) {
      return;
    }

    const $count = $facet.getElementsByClassName(bcl.c.distributor.facets.props.classNameCount);

    if ($count && $count[0]) {
      $count[0].innerText = facet.count;
    }

    bcl.u.removeClass($facet, bcl.c.distributor.props.classNameHidden);
    bcl.u.addClass($facet, "facetShow");
  },

  updateFacet: function (facet, $dimension) {
    const $facet = $dimension.querySelector("[" + bcl.c.distributor.facets.props.nameAttrDataFacet + '="' + facet.selector + '"]');

    if (!$facet) {
      return;
    }

    const $count = $facet.getElementsByClassName(bcl.c.distributor.facets.props.classNameCount);

    if ($count && $count[0]) {
      $count[0].innerText = facet.count;
    }

    bcl.u.removeClass($facet, bcl.c.distributor.props.classNameHidden);
    bcl.u.addClass($facet, "facetShow");
  },

  getData: function () {
    const obj = {};

    for (const key in bcl.c.distributor.facets.props.data) {
      obj[key] = Object.keys(bcl.c.distributor.facets.props.data[key]);
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].join(",");
      }
    }

    bcl.u.forEach(bcl.c.distributor.facets.getSelector(obj), function (selectors) {
      const selector = selectors.split(";");
      if (obj[selector[0]]) {
        obj[selector[0]] = obj[selector[0]] + "," + selector[1];
      } else {
        obj[selector[0]] = selector[1];
      }
    });

    return obj;
  },

  getSelector: function (obj) {
    if (!obj.hotel_destinations && !obj.hotel_brand && !obj.hotel_themes && !obj.hotel_rate) {
      const selectorData = document.querySelector("." + bcl.c.distributor.facets.props.classNameComparator).dataset.selectorsdata;
      if (selectorData) {
        return selectorData.replace("[", "").replace("]", "").replace(" ", "").split(",");
      }
    }
    return [];
  },

  checkClickeds: function ($dimension) {
    const dimensionValue = $dimension.dataset.dimension;

    if (!bcl.c.distributor.facets.props.showMoreClickeds.includes(dimensionValue)) {
      bcl.c.distributor.facets.props.showMoreClickeds.push(dimensionValue);
    } else {
      bcl.c.distributor.facets.props.showMoreClickeds = bcl.c.distributor.facets.props.showMoreClickeds.filter(function (data) {
        return data !== dimensionValue;
      });
    }
  },

  blockFacets: function () {
    const $dimensions = bcl.c.distributor.facets.props.$container.getElementsByClassName(bcl.c.distributor.facets.props.classNameDimension);

    if (!$dimensions || bcl.c.distributor.facets.props.$container.closest("." + bcl.c.distributor.facets.props.offerCollectionsComparatorSelector)) {
      return;
    }

    bcl.u.forEach($dimensions, function ($dimension) {
      const $facets = $dimension.querySelectorAll(".facet input");
      const dimension = $dimension.getAttribute(bcl.c.distributor.facets.props.nameAttrDataDimension);
      bcl.u.forEach($facets, function ($facet) {
        const facet = $facet.parentNode.parentNode.getAttribute(bcl.c.distributor.facets.props.nameAttrDataFacet);
        if ($facet.checked) {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get("startDate") || urlParams.get("endDate")) {
            $facet.disabled = true;
          }
          if (bcl.c.distributor.facets?.props?.$containerFacetsActive && !bcl.c.distributor.facets?.props?.$containerFacetsActive?.closest("." + bcl.c.distributor.facets.props.classNameComparator)?.dataset?.selectors) {
            bcl.c.distributor.facets.clickInFacet($facet, dimension, facet);
          }
        }
      });
    });
  },

  selectedCheckedFacet: function () {
    const $dimensions = bcl.c.distributor.facets.props.$container.getElementsByClassName(bcl.c.distributor.facets.props.classNameDimension);

    if (!$dimensions) {
      return;
    }

    bcl.u.forEach($dimensions, function ($dimension) {
      const $facets = $dimension.querySelectorAll(".facet");
      const dimension = $dimension.getAttribute(bcl.c.distributor.facets.props.nameAttrDataDimension);
      bcl.u.forEach($facets, function ($facet) {
        const facet = $facet.getAttribute(bcl.c.distributor.facets.props.nameAttrDataFacet);
        const $checkbox = $facet.querySelector("input");
        if ($checkbox?.dataset.checked === "true") {
          $checkbox.checked = true;
          bcl.c.distributor.facets.clickInFacet($checkbox, dimension, facet);
        }
      });
    });
  },
};
