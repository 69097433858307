bcl.c.distributor.results.initOfferCard = function ($card, $article, data) {
  if (!bcl.u.containsClass($article, "c-card-hotel-offer-JS")) {
    return;
  }

  bcl.u.setButtonOfferCard($article, data);
  bcl.c.distributor.results.detailOffer($card, $article, data);
  bcl.s.countdown.init($article);
};

bcl.c.distributor.offerFetchUtag = function ($article, data) {
  const offerID = $article.getAttribute("data-offer-id");

  if (offerID) {
    let campaignID = $article.getAttribute("data-offer-id-campaign");
    if (campaignID) {
      campaignID = campaignID.split("/").pop();
    } else {
      campaignID = data.offer.name;
    }

    bcl.c.distributor.props.utagData.promo_id.push(offerID);
    bcl.c.distributor.props.utagData.promo_creative.push(utag_data.ecommerce_action_field_list_gtag);
    bcl.c.distributor.props.utagData.promo_value.push($article.getAttribute("data-offer-value"));
    bcl.c.distributor.props.utagData.promo_name.push(campaignID);
    bcl.c.distributor.props.utagData.promo_value_type.push($article.getAttribute("data-offer-type"));
    bcl.c.distributor.props.utagData.promo_position.push(bcl.c.distributor.props.utagData.promo_position.length + 1 + "");
    return true;
  }

  return false;
};

bcl.c.distributor.results.detailOffer = function ($card, $article, data) {
  const $button = $article.querySelector(".open-detail-JS");

  if (!$button) {
    return;
  }

  $button.addEventListener("click", function () {
    const status = bcl.c.distributor.isLoadFullData($card, $article, true);

    const obj = {
      $button: $button,
      $card: $card,
      $article: $article,
      data: data,
    };

    bcl.c.distributor.results.props.scrollOpenDetailOffer = window.scrollY || document.documentElement.scrollTop;
    bcl.c.distributor.results.props.dataOfferPendingOpen = obj;
    if (status) {
      bcl.c.distributor.results.openDetailOffer(obj);
    } else {
      bcl.c.distributor.requestAsyncData(bcl.c.distributor.results.clickPagination);
    }
  });
};

bcl.c.distributor.results.openDetailAfterLoadFullData = function (index) {
  if (!bcl.c.distributor.results.props.dataOfferPendingOpen) {
    return;
  }

  bcl.c.distributor.results.props.dataOfferPendingOpen.data = bcl.c.distributor.props.data[index];

  bcl.c.distributor.results.openDetailOffer(bcl.c.distributor.results.props.dataOfferPendingOpen);

  bcl.u.triggerEvent(window, "cmp-image-global-redraw");

  bcl.c.distributor.results.props.dataOfferPendingOpen = null;
};

bcl.c.distributor.results.openDetailOffer = function (obj) {
  if (!bcl.c.distributor.results.props.$detailOffer) {
    bcl.c.distributor.results.initDetailOffer(obj);
  }

  bcl.c.distributor.results.props.lastDetailOffer = obj;
  if (!bcl.c.distributor.results.props.moreInfoClicked) {
    bcl.u.triggerEvent(window, "cmp-image-global-redraw-all");
    bcl.c.distributor.results.props.moreInfoClicked = true;
  }

  bcl.c.distributor.results.processDetailOffer(obj);
  bcl.c.distributor.results.processDataForClone(obj, ".c-container-image.mod--img-zoom");
  bcl.c.distributor.results.processDateForClone(obj, ".c-card-dates");
  bcl.c.distributor.results.processDataForClone(obj, ".c-card-coupon__promo-discount");
  bcl.c.distributor.results.processDataForClone(obj, ".promo-info-JS");

  bcl.u.setButtonOfferCard(bcl.c.distributor.results.props.$detailOffer, obj.data);
  bcl.c.distributor.results.addOfferinFB(obj);

  bcl.s.countdown.init(bcl.c.distributor.results.props.$detailOffer);
  bcl.c.coupon?.init(bcl.c.distributor.results.props.$detailOffer);

  bcl.c.distributor.results.setPathOffer(obj);

  bcl.u.addClass(document.body, "activePaginated");
  bcl.u.addClass(document.body, "fastbooking-on-detail-offer");

  bcl.u.removeBhgLoading();

  bcl.u.removeClass(bcl.c.distributor.results.props.$detailOffer, "hidden");

  bcl.c.distributor.results.moveFbInDialog();
  bcl.c.distributor.results.actionShowCharacteristicPromo(true);
};

bcl.c.distributor.results.showCharacteristicPromo = function (currentDialog, $divCharacteristic, $divPolicies) {
  $divCharacteristic = currentDialog.querySelector(".promo-info-JS");
  $divPolicies = currentDialog.querySelector(".container-text-JS");

  $divCharacteristic && bcl.u.removeClass($divCharacteristic, "hidden");
  $divPolicies && bcl.u.addClass($divPolicies, "hidden");
};

bcl.c.distributor.results.hiddeCharacteristicPromo = function (currentDialog, $divCharacteristic, $divPolicies, $childCharacteristic) {
  $divCharacteristic = currentDialog.querySelector(".promo-info-JS");
  $childCharacteristic = $divCharacteristic.children[0];
  $divPolicies = currentDialog.querySelector(".container-text-JS");

  $divCharacteristic && $childCharacteristic && $divCharacteristic.removeChild($childCharacteristic);
  $divPolicies && bcl.u.addClass($divPolicies, "hidden");
};

bcl.c.distributor.results.actionShowCharacteristicPromo = function (show) {
  const currentDialog = document.querySelector(".detail-offer-JS"),
    $divCharacteristic = null,
    $childCharacteristic = null,
    $divPolicies = null;

  if (currentDialog) {
    show ? bcl.c.distributor.results.showCharacteristicPromo(currentDialog, $divCharacteristic, $divPolicies) : bcl.c.distributor.results.hiddeCharacteristicPromo(currentDialog, $divCharacteristic, $divPolicies, $childCharacteristic);
  }
};

bcl.c.distributor.results.moveFbInDialog = function () {
  if (bcl.u.containsClass(document.body, "activePaginated") && document.querySelector(".c-fastbooking")) {
    const fastBookingToMove = document.querySelector(".c-fastbooking"),
      currentDialog = document.querySelector(".detail-offer-JS");

    if (currentDialog && currentDialog.querySelector(".c-detail-offer__fastbooking-container.fastbooking")) {
      const containerFBinDialog = currentDialog.querySelector(".c-detail-offer__fastbooking-container.fastbooking");
      containerFBinDialog.appendChild(fastBookingToMove);
    }
  }
};

bcl.c.distributor.results.initDetailOffer = function (obj) {
  if (obj?.$card.classList.contains("JS-offer-destination-card")) {
    bcl.c.distributor.results.props.$detailOffer = obj.$card.closest(".JS-offers-destination")?.querySelector(".detail-offer-JS");
  } else {
    bcl.c.distributor.results.props.$detailOffer = bcl.c.distributor.props.$container.querySelector(".detail-offer-JS");
  }
  const $back = bcl.c.distributor.results.props.$detailOffer.querySelector(".back-JS");

  if ($back) {
    $back.addEventListener("click", bcl.c.distributor.results.closeDetailOffer);
  }

  bcl.c.distributor.results.addClickPrevOrNextDetailOffer(obj);
};

bcl.c.distributor.results.addOfferinFB = function (obj) {
  if (!obj || (bcl.c.fastbooking.destination.props.data != null && !bcl.c.distributor.results.props.cleanDestination)) {
    return;
  }

  let selector,
    redirect = false;
  const data = obj.data,
    $button = obj.$button;

  const dateStart = new Date(data.offer.travel_start);
  if (dateStart > new Date() && !bcl.c.fastbooking.calendar.props.data.startDate && !bcl.c.fastbooking.calendar.props.data.endDate) {
    bcl.c.fastbooking.calendar.goToDate(dateStart);
  } else {
    bcl.c.fastbooking.calendar.goToDate(new Date());
  }

  if (bcl.u.containsClass($button, "coupon-button-JS")) {
    bcl.c.fastbooking.discount.addPromocode($button.dataset.value, false);
  } else {
    selector = "calendarpromo";
    redirect = true;
  }

  obj = {
    destination: {
      selector: selector,
      code: data.offer.id_category,
      id: data.info.hotelId,
      data: {
        adultsOnly: data.info.is_hotel_adults_only,
        minAdultsAge: data.info.minimum_age_for_adults,
        closingDate: data.info.hotel_closing_date,
        openingDate: data.info.hotel_opening_date,
        specialDiscounts: data.info.specialDiscounts,
      },
      label: data.info.search_title || data.info.name,
      type: "hotel",
    },
    offer: data.offer,
    redirect: redirect,
  };

  bcl.c.fastbooking.setData(obj, true);
  bcl.c.distributor.results.props.cleanDestination = true;

  if (bcl.c.fastbooking.discount.props.$containerCheckbox) {
    bcl.c.fastbooking.discount.cleanCheckbox();
  }
};

bcl.c.distributor.results.removeOfferinFB = function () {
  if (bcl.c.distributor.results.props.cleanDestination) {
    bcl.c.fastbooking.destination.cleanDestination();
    bcl.c.fastbooking.calendar.goToDate(new Date());
  }

  bcl.c.distributor.results.props.cleanDestination = false;
};

bcl.c.distributor.results.addClickPrevOrNextDetailOffer = function (obj) {
  const $buttons = bcl.c.distributor.results.props.$detailOffer.querySelectorAll(".prev-or-next-JS");

  bcl.u.forEach($buttons, function ($button) {
    $button.addEventListener("click", function () {
      let children;
      if (obj?.$card.classList.contains("JS-offer-destination-card")) {
        children = obj.$card.closest(".JS-offers-destination").querySelectorAll(".JS-offer-destination-card");
      }
      const i = bcl.c.distributor.results.props.lastDetailOffer[$button.dataset.action],
        $card = children ? children[i] : bcl.c.distributor.results.props.$container.children[i],
        $moreInfo = $card.querySelector(".open-detail-JS");

      bcl.c.fastbooking.discount.cleanCheckbox();
      bcl.u.triggerEvent($moreInfo, "click");
    });
  });
};

bcl.c.distributor.results.moveBackFbInBody = function () {
  if (bcl.u.containsClass(document.body, "activePaginated") && document.querySelectorAll(".fastbooking:not(.c-detail-offer__fastbooking-container)")) {
    const fastBookingContainerEmpty = document.querySelectorAll(".fastbooking:not(.c-detail-offer__fastbooking-container)")[0],
      currentDialog = document.querySelector(".detail-offer-JS");

    if (currentDialog && currentDialog.querySelector(".c-detail-offer__fastbooking-container.fastbooking")) {
      const containerFBinDialog = currentDialog.querySelector(".c-detail-offer__fastbooking-container.fastbooking"),
        fastBookingInDialog = containerFBinDialog.querySelectorAll(".c-fastbooking");
      if (fastBookingInDialog && fastBookingInDialog[0]) {
        fastBookingContainerEmpty.appendChild(fastBookingInDialog[0]);
      }
    }
  }
};

bcl.c.distributor.results.closeDetailOffer = function () {
  bcl.c.distributor.results.moveBackFbInBody();
  bcl.c.distributor.results.actionShowCharacteristicPromo(false);

  bcl.u.removeClass(document.body, "activePaginated");
  bcl.u.removeClass(document.body, "fastbooking-on-detail-offer");

  bcl.c.distributor.results.removeOfferinFB();

  window.scrollTo(0, bcl.c.distributor.results.props.scrollOpenDetailOffer);

  bcl.u.addClass(bcl.c.distributor.results.props.$detailOffer, "hidden");

  bcl.c.socialSharing.props.shareUrl = null;
};

bcl.c.distributor.results.searchPrevAndNextDetailOffers = function (obj) {
  let children, length;
  if (obj?.$card.classList.contains("JS-offer-destination-card")) {
    children = obj.$card.closest(".JS-offers-destination").querySelectorAll(".JS-offer-destination-card");
    length = children.length;
  } else {
    children = bcl.c.distributor.results.props.$container.children;
    length = children.length - 1;
  }
  let i,
    $item,
    firstShow = -1,
    next = null,
    prev = null,
    current = null,
    currentShow = 1;

  for (i = 0; i < length; i++) {
    $item = children[i];

    if (bcl.u.containsClass($item, "hidden")) {
      continue;
    }

    if (current !== null) {
      next = i;
      break;
    }

    if ($item === obj.$card) {
      current = i;
    } else {
      if (firstShow === -1) {
        firstShow = i;
      }
      currentShow++;
      prev = i;
    }
  }

  if (prev === null) {
    for (i = children.length - 2; i > current; i--) {
      $item = children[i];

      if (bcl.u.containsClass($item, "hidden")) {
        continue;
      }

      if (firstShow === -1) {
        firstShow = i;
      }

      prev = i;

      break;
    }
  }

  if (next === null) {
    next = firstShow;
  }

  obj.prev = prev;
  obj.current = current;
  obj.currentOffer = currentShow;
  obj.next = next;
};

bcl.c.distributor.results.processDetailOffer = function (obj) {
  bcl.c.distributor.results.searchPrevAndNextDetailOffers(obj);
  if (obj?.$card.classList.contains("JS-offer-destination-card")) {
    obj.total = obj.$card.closest(".JS-offers-destination").querySelectorAll(".c-card-hotel-offer-JS").length;
  } else {
    obj.total = bcl.c.distributor.total.props.$container.innerText;
  }
  bcl.c.distributor.results.processDataDetailOffer(obj, "data-attr");
  bcl.c.distributor.results.showTextDependencies(obj, "data-attr");

  let method = "addClass";

  if (obj.prev != null) {
    bcl.c.distributor.results.processDataDetailOffer(bcl.c.distributor.props.data[obj.prev], "data-attr-prev");
    bcl.c.distributor.results.processDataDetailOffer(bcl.c.distributor.props.data[obj.next], "data-attr-next");
    method = "removeClass";
  }

  const $buttons = bcl.c.distributor.results.props.$detailOffer.querySelectorAll(".prev-or-next-JS");

  bcl.u.forEach($buttons, function ($button) {
    bcl.u[method]($button, "hidden");
  });
};

bcl.c.distributor.results.showTextDependencies = function () {
  if (!bcl.c.distributor.results.props.$detailcontainerTextOffer) {
    bcl.c.distributor.results.props.$detailcontainerTextOffer = bcl.c.distributor.results.props.$detailOffer.getElementsByClassName("container-text-JS")[0];
  }

  if (!bcl.c.distributor.results.props.$detailSeparatorOffer) {
    bcl.c.distributor.results.props.$detailSeparatorOffer = bcl.c.distributor.results.props.$detailOffer.getElementsByClassName("separator-JS")[0];
  }

  bcl.u.addClass(bcl.c.distributor.results.props.$detailcontainerTextOffer, "hidden");
  bcl.u.addClass(bcl.c.distributor.results.props.$detailSeparatorOffer, "hidden");

  const $items = bcl.c.distributor.results.props.$detailcontainerTextOffer.getElementsByClassName("text-JS");
  let statusShow = false;

  bcl.u.forEach($items, function ($item) {
    if ($item.innerText) {
      statusShow = true;
      bcl.u.removeClass($item.parentNode, "hidden");
    } else {
      bcl.u.addClass($item.parentNode, "hidden");
    }
  });

  if (statusShow) {
    bcl.u.removeClass(bcl.c.distributor.results.props.$detailcontainerTextOffer, "hidden");
    bcl.u.removeClass(bcl.c.distributor.results.props.$detailSeparatorOffer, "hidden");
  }
};

bcl.c.distributor.results.processDataDetailOffer = function (obj, dataAttr) {
  const $nodes = bcl.c.distributor.results.props.$detailOffer.querySelectorAll("[" + dataAttr + "]");

  bcl.u.forEach($nodes, function ($item) {
    const attr = $item.getAttribute(dataAttr),
      text = bcl.u.getInData(obj, attr);
    let method = "innerText",
      hidden = true;

    if ($item.dataset.html) {
      method = "innerHTML";
    }

    if ($item.dataset.nohidden) {
      hidden = false;
    }

    if (text) {
      $item[method] = text;
      hidden && bcl.u.removeClass($item, "hidden");
    } else {
      hidden && bcl.u.addClass($item, "hidden");
    }
  });
};

bcl.c.distributor.results.processDateForClone = function (obj) {
  const className = ".c-card-dates";
  const classNameAlert = ".c-card-dates__alert";
  let $counterCard, $booking, $travel, $counter;

  const $nodeCard = obj.$card.querySelector(className);
  if ($nodeCard) {
    $counterCard = $nodeCard.querySelector(classNameAlert);
  }

  const $container = bcl.c.distributor.results.props.$detailOffer.querySelector(className);

  if ($container) {
    $booking = $container.querySelector(".booking-dates-JS");
    $travel = $container.querySelector(".travel-dates-JS");
    $counter = $container.querySelector(classNameAlert);
  }

  if ($counterCard && $booking) {
    if ($counter) {
      $container.removeChild($counter);
    }

    const $clone = $counterCard.cloneNode(true);
    $container.insertBefore($clone, $booking.parentNode);
  }

  if ($nodeCard && $nodeCard.dataset.bookingdate === "true") {
    bcl.c.distributor.results.processDate(obj, $booking, "booking");
  }

  bcl.c.distributor.results.processDate(obj, $travel, "travel");
};

bcl.c.distributor.results.processDate = function (obj, $node, name) {
  const offer = obj.data.offer,
    hiddenClass = "hidden",
    dateOptions = { year: "numeric", day: "numeric", month: "numeric" };
  let start = offer[name + "_start"],
    end = offer[name + "_end"];

  if ($node && start && end) {
    const $nodes = $node.getElementsByClassName("c-card-dates__periods-date-book");

    start = new Date(start);
    end = new Date(end);

    $nodes[0].innerText = start.toLocaleDateString(bcl.c.fastbooking.calendar.props.location, dateOptions);
    $nodes[1].innerText = end.toLocaleDateString(bcl.c.fastbooking.calendar.props.location, dateOptions);

    bcl.u.removeClass($node, hiddenClass);
  } else {
    bcl.u.addClass($node, hiddenClass);
  }
};

bcl.c.distributor.results.processDataForClone = function (obj, className) {
  const $node = obj.$card.querySelector(className),
    $clone = $node && $node.cloneNode(true),
    $container = bcl.c.distributor.results.props.$detailOffer.querySelector(className);

  if (!$clone || !$clone.children.length) {
    return;
  }

  while ($container.children.length != 0) {
    $container.removeChild($container.children[0]);
  }

  while ($clone.children.length != 0) {
    $container.appendChild($clone.children[0]);
  }

  return true;
};

bcl.c.distributor.results.setPathOffer = function (obj) {
  const shareUrl = bcl.c.distributor.props.$container ? bcl.c.distributor.props.$container.getAttribute("data-urlShare") : null;

  if (shareUrl) {
    bcl.c.socialSharing.props.shareUrl = shareUrl + bcl.c.distributor.results.minifyPath(obj.data.offer.path) + "/";
  } else {
    bcl.c.socialSharing.props.shareUrl = document.location.href;
  }
};

bcl.c.distributor.results.afterOffersInitCards = function () {
  bcl.c.distributor.results.checkLinkedOffers();
};

bcl.c.distributor.results.checkLinkedOffers = function () {
  const data = bcl.c.distributor.props.$container.getAttribute("data-comparator");
  if (!data) {
    return;
  }
  const paths = data.split(",");
  if (paths.length == 1) {
    // detail
    let $button = null;
    const $cardsButton = bcl.c.distributor.results.props.$container.querySelectorAll("[data-open]");
    bcl.u.forEach($cardsButton, function ($cardButton) {
      const path = paths[0].split("/");
      const promoCode = path[path.length - 1];
      if ($cardButton.dataset.open.includes(promoCode)) {
        $button = $cardButton;
      }
    });

    if (!$button) {
      return;
    }
    bcl.u.triggerEvent($button, "click");
  } else {
    // comparator
    bcl.c.distributor.comparator.props.paths = paths;
  }
};
